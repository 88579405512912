/** @format */

import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { DeleteOutline } from "@mui/icons-material";
import "../editModal.css";
import {
  fethLocations,
  deleteLocation,
} from "../../services/private/settings/SettingsService";
// import AddEditLocationModal from "../../modals/AddEditLocationModal";
import AddLocationModal from "../../modals/AddLocationModal";
import DeleteModal from "../../modals/DeleteModal";
import UpdateLocationModal from "../../modals/UpdateLocationModal";
import { errorToast, successToast } from "../../utils/alerts";
import EditIcon from "../../assets/icons/EditIcon";
import theme from "../theme";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.esgblueblack.main,
    color: theme.palette.common.white,
    fontSize: 20,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const GeneralTab = ({ companyDetail }) => {
  const [locations, setLocations] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [labelModal, setLabelModal] = useState(false);
  const [headAvailable, setHeadAvailable] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState({
    name: null,
    type: null,
    start: null,
    end: null,
  });

  const listLocation = async () => {
    try {
      setLoading(true);
      const response = await fethLocations();
      await setLocations(response.data);
      checkHeadAvailable();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error.message);
    }
  };

  const checkHeadAvailable = () => {
    const entity = locations.find(
      (location) => location.type === "Headquarter"
    );
    if (entity) {
      setHeadAvailable(false);
    } else {
      setHeadAvailable(true);
    }
  };

  const handelAddModal = () => {
    setAddModal(true);
    setLabelModal("Add Location");
  };

  const handelDeleteModalClose = async () => {
    setDeleteModal(false);
    setSelectedLocation({
      name: null,
      type: null,
      start: null,
      end: null,
    });
  };

  const handelDelete = async (id) => {
    try {
      await deleteLocation(id);
      handelDeleteModalClose();
      await listLocation();
      successToast("Deleted Successfully!");
    } catch (err) {
      errorToast(err.message);
    }
  };

  const handelUpdateModal = async (location) => {
    await setSelectedLocation(location);
    setEditModal(true);
  };

  useEffect(() => {
    listLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkHeadAvailable();
    // eslint-disable-next-line
  }, [locations]);

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="p" className="page-headline">
        Basic Information
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        width="95%"
        justifyContent="space-between"
        gap="40px"
        marginTop="32px"
      >
        <TextField
          sx={{ width: "30%", fontSize: "14px" }}
          className="general--input"
          label="Business Name"
          variant="outlined"
          value={companyDetail?.register_company_name || ""}
        />
        <TextField
          sx={{ width: "30%", fontSize: "14px" }}
          className="general--input"
          label="Industry Type"
          variant="outlined"
          value={companyDetail?.company_industry || ""}
        />
        <TextField
          sx={{ width: "30%" }}
          value={companyDetail?.email || ""}
          className="general--input"
          label="Email"
          variant="outlined"
          type="email"
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ margin: "24px 0" }}
      >
        <Typography variant="p" className="page-headline">
          Our Locations
        </Typography>
        <Button
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={handelAddModal}
        >
          Add New Location
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="150px">Series No.</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
              <StyledTableCell width="150px" align="center">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations?.map((location, index) => (
              <StyledTableRow key={location?.id}>
                <StyledTableCell width="150px">
                  {"#" + (index + 1)}
                </StyledTableCell>

                <StyledTableCell component="th" scope="row">
                  {location?.location}
                  {location?.type !== "Other" && (
                    <Chip label={location?.type} sx={{ marginLeft: "16px" }} />
                  )}
                </StyledTableCell>
                <StyledTableCell width="150px">
                  <Stack
                    direction="row"
                    justifyContent="space-around"
                    className="modal--btn-container"
                  >
                    <IconButton
                      size="small"
                      onClick={() => handelUpdateModal(location)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ color: theme.palette.esgblueblack.main }}
                      onClick={() => {
                        setSelectedLocation(location);
                        setDeleteModal(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                    {/* <IconButton onClick={() => handelUpdateModal(location)}>
                      <ModeEditOutlined sx={{ fontSize: 20 }} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedLocation(location);
                        setDeleteModal(true);
                      }}
                    >
                      <DeleteOutlineOutlined sx={{ fontSize: 20 }} />
                    </IconButton> */}
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddLocationModal
        label={labelModal}
        addModal={addModal}
        setAddModal={setAddModal}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        locations={locations}
        requestServer={listLocation}
        headAvailable={headAvailable}
      />

      <UpdateLocationModal
        label={labelModal}
        addModal={editModal}
        setAddModal={setEditModal}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        locations={locations}
        requestServer={listLocation}
        headAvailable={headAvailable}
      />

      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handelClose={handelDeleteModalClose}
          label={"Are you sure you want to delete this?"}
          description={
            "By deleting this location all the assosiated records for this location will be deleted automatically."
          }
          deleteRow={handelDelete}
          id={selectedLocation?.id}
        />
      )}
    </Box>
  );
};

export default GeneralTab;
