import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddNewTargets from './AddNewTargets';
import { fetchTargetDetail } from '../../services/private/targets/TargetService';
import { errorToast } from '../../utils/alerts';
const UpdateTarget = () => {

    const { id } = useParams();
    const [entity, setEntity] = useState({});
    const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(true);

    const requestTargetDetails = async()=> {

        try{
            setLoading(true);
            const entity = await fetchTargetDetail(id);
            await setEntity(entity.data);
            await setActions(entity.data.actions);
            setLoading(false);

            
        }catch(error){
            setLoading(false);
            errorToast(error.message);
        }
    }

    useEffect(() => {
        requestTargetDetails();
        // eslint-disable-next-line
    }, []);

  return (
    <div>
    {!loading && (
        <AddNewTargets details={entity} lactions={actions} type={'update'}/>
    )}
    </div>
  )
}

export default UpdateTarget