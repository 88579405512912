/** @format */

import React, { useState, useEffect } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import "./addNewTargets.css";
import AddTarget from "./AddTarget";
import AddAction from "./AddAction";
import {
  fethCategories,
  fethDivisions,
  fethSubUsers,
  fethLocations,
} from "../../services/private/config/config";
import ReviewTarget from "./ReviewTarget";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorToast } from '../../utils/alerts';
const steps = ["Create Target", "Add Action Plan", "Review"];

const AddNewTargets = ({ details, lactions, type }) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [categories, setCategories] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [subusers, setSubusers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [actions, setActions] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [targetDetails, setTargetDetails] = useState({
    id: details?.id,
    type: details?.type,
    category: details?.category,
    category_id: details?.category_id,
    division: details?.division,
    division_id: details?.division_id,
    shift: details?.shift,
    shift_type: details?.shift_type,
    shift_value: details?.shift_value,
    achieve_by: details?.achieve_by,
    location_id: details?.location_id,
    location: details?.location,
    assign_to_id: details?.assign_to_id,
    assign_to: details?.assign_to,
  });

  const userDetails = useSelector((state) => state.auth.user);

  const handelTargetChange = async (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      setTargetDetails((prev) => ({
        ...prev,
        category: null,
        division: null,
      }));

      if (value === "1" || value === "2" || value === "3") {
        getCategories(value);
      }
    }

    if (name === "category") {
      const entity = await categories.find((cat) => cat.name === value);
      await getDivisions(entity?.id);
    }
    setTargetDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNext = () => {
    if (activeStep === 2) navigate("/targets");
    if (activeStep < 3) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getSubUsers = async () => {
    try {
      let entities = await fethSubUsers();
      let payload = {
        id: userDetails.id,
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        role: userDetails.role,
      };
      entities.data.unshift(payload);

      setSubusers(entities.data);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const fetchLocations = async () => {
    try {
      const entities = await fethLocations();

      if (entities.data.length === 0) {
        errorToast("Please add location first before adding target");
        navigate("/targets");
      }
      setLocations(entities.data);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const getCategories = async (value) => {
    try {
      const categories = await fethCategories(value);
      await setCategories(categories.data);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const getDivisions = async (category) => {
    try {
      const entities = await fethDivisions(category);
      setDivisions(entities.data);
    } catch (err) {
      errorToast(err.message);
    }
  };

  const fetchConfigData = async () => {
    getSubUsers();
    fetchLocations();

    // eslint-disable-next-line
    if(targetDetails?.type == '1' || targetDetails?.type == '2' || targetDetails?.type == '3') {
      if (targetDetails?.type) {
        await getCategories(targetDetails?.type);
      }
      if (targetDetails?.category) {
        await getDivisions(targetDetails?.category_id);
      }
    }

  };

  useEffect(() => {
    setActions([]);
    fetchConfigData();
    setActions(lactions || []);
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="stepper-form">
      <Stepper
        activeStep={activeStep}
        sx={{ marginBottom: "32px", maxWidth: "800px" }}
      >
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === 0 && (
        <AddTarget
          handleNext={handleNext}
          handelTargetChange={handelTargetChange}
          targetDetails={targetDetails}
          locations={locations}
          subusers={subusers}
          categories={categories}
          divisions={divisions}
          actionList={lactions}
          setActions={setActions}
        />
      )}
      {activeStep === 1 && (
        <AddAction
          handleNext={handleNext}
          handleBack={handleBack}
          targetDetails={targetDetails}
          actions={actions}
          setActions={setActions}
        />
      )}
      {activeStep === 2 && (
        <ReviewTarget
          handleNext={handleNext}
          handleBack={handleBack}
          targetDetails={targetDetails}
          actions={actions}
          type={type}
          setActions={setActions}
        />
      )}
    </Box>
  );
};

export default AddNewTargets;
