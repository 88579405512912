/** @format */

import React from "react";
import {
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  Stack,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from "@mui/material";
import "./addNewTargets.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { errorToast } from '../../utils/alerts';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddTarget = ({
  handleNext,
  handelTargetChange,
  targetDetails,
  locations,
  subusers,
  categories,
  divisions,
  actionList,
  setActions
}) => {
  const navigate = useNavigate();
  const scopes = [
    { label: "Energy consumption", value: "0" },
    { label: "Scope 1", value: "1" },
    { label: "Scope 2", value: "2" },
    { label: "Scope 3", value: "3" },
    { label: "Water", value: "4" },
    { label: "Waste", value: "5" },
  ];

  const handelValueChange = async (e) => {
    triggerExtraEvents(e);
    handelTargetChange(e);
  };

  const triggerExtraEvents = async (e) => {
    const { name, value } = e.target;

    if (name === "category") {
      let category = await categories.find(
        (category) => category.name === value
      );
      let tempEvent = {
        target: {
          name: "category_id",
          value: category.id,
        },
      };
      handelTargetChange(tempEvent);
    }

    if (name === "division") {
      let division = await divisions.find(
        (division) => division.name === value
      );
      let tempEvent = {
        target: {
          name: "division_id",
          value: division.id,
        },
      };
      handelTargetChange(tempEvent);
    }

    if (name === "location") {
      let location = await locations.find(
        (location) => location.location === value
      );
      let tempEvent = {
        target: {
          name: "location_id",
          value: location.id,
        },
      };
      handelTargetChange(tempEvent);
    }

    if (name === "assign_to") {
      let names = value.split(" ");
      let entity = await subusers.find(
        (user) => user.firstName === names[0] && user.lastName === names[1]
      );
      let tempEvent = {
        target: {
          name: "assign_to_id",
          value: entity.id,
        },
      };
      handelTargetChange(tempEvent);
    }
  };

  const handelNextStep = async () => {
    if ( !targetDetails?.type || !targetDetails?.shift || !targetDetails?.shift_type || !targetDetails?.shift_value || !targetDetails?.achieve_by || !targetDetails?.location_id || !targetDetails?.assign_to ) {
      errorToast("All fields are required");
    } else if (
      (targetDetails.type === "1" ||
        targetDetails.type === "2" ||
        targetDetails.type === "3") &&
      (targetDetails.category === null || targetDetails.division === null)
    ) {
      errorToast("All fields are required");
    } else if (targetDetails.shift_value === "") {
      errorToast("All fields are required");
    } else {
      // setActions(actionList || []);
      handleNext();
    }
    // handleNext();
  };

  return (
    <Stack
      direction="column"
      gap={"24px"}
      max-width="800px"
      // sx={{ backgroundColor: "red" }}
    >
      <FormControl sx={{ width: "100%", fontFamily: "inherit" }}>
        <InputLabel id="demo-scope-label">Select Scope</InputLabel>
        <Select
          labelId="demo-scope-label"
          id="demo-scope"
          value={targetDetails?.type || ''}
          name="type"
          onChange={(e) => handelValueChange(e)}
          input={<OutlinedInput label="Select Scope" />}
          MenuProps={MenuProps}
        >
          {scopes.map((scope) => (
            <MenuItem
              key={scope?.value}
              value={scope?.value}
              id="input-dropdown"
            >
              {scope?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {(targetDetails?.type === "1" ||
        targetDetails?.type === "2" ||
        targetDetails?.type === "3") && (
        <div>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-category-label">Select Category</InputLabel>
            <Select
              labelId="demo-category-label"
              name="category"
              value={targetDetails?.category || ''}
              onChange={(e) => handelValueChange(e)}
              input={<OutlinedInput label="Select Category" />}
              MenuProps={MenuProps}
            >
              {categories.map((category) => (
                <MenuItem key={category?.id} value={category?.name}>
                  {category?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      {(targetDetails?.type === "1" ||
        targetDetails?.type === "2" ||
        targetDetails?.type === "3") &&
        targetDetails?.category && (
          <div>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-division-label">Select Division</InputLabel>
              <Select
                labelId="demo-division-label"
                id="demo-division"
                name="division"
                value={targetDetails?.division || ''}
                onChange={(e) => handelValueChange(e)}
                input={<OutlinedInput label="Select Division" />}
                MenuProps={MenuProps}
              >
                {divisions.map((division) => (
                  <MenuItem key={division?.id} value={division?.name}>
                    {division?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      {(targetDetails.type === "0" ||
        targetDetails.type === "4" ||
        targetDetails.type === "5" ||
        targetDetails.division) && (
        <FormControl sx={{ width: "100%" }}>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="radio-label"
          >
            Select Any One
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="shift"
            value={targetDetails?.shift || ''}
            onChange={(e) => handelTargetChange(e)}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              id="radio-text"
              label="Decrease by"
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              id="radio-text"
              label="Increase by"
            />
          </RadioGroup>
        </FormControl>
      )}

      {targetDetails?.shift && (
        <FormControl sx={{ width: "100%" }}>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="radio-label"
          >
            Select Any One
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="shift_type"
            value={targetDetails?.shift_type || ''}
            onChange={(e) => handelTargetChange(e)}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              id="radio-text"
              label="Percentage"
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              id="radio-text"
              label="Value"
            />
          </RadioGroup>
        </FormControl>
      )}

      {targetDetails?.shift_type === "" ||
        (targetDetails?.shift_type && (
          <TextField
            label={`Enter ${
              targetDetails?.shift_type === "0" ? "Percentage" : "Value"
            }`}
            sx={{ width: "100%" }}
            type="number"
            name="shift_value"
            value={targetDetails?.shift_value || ''}
            onChange={(e) => handelTargetChange(e)}
          />
        ))}

      {targetDetails?.shift_value && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={dayjs(targetDetails?.achieve_by || '')}
              onChange={(val) => {
                let newVal = {
                  target: {
                    name: "achieve_by",
                    value: val,
                  },
                };
                handelTargetChange(newVal);
              }}
              disablePast={true}
              sx={{
                width: "100%",
                mt: 2,
              }}
              label="Achieve This Target By"
            />
          </DemoContainer>
        </LocalizationProvider>
      )}

      {targetDetails?.achieve_by && (
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="demo-location-label">Assign To</InputLabel>
          <Select
            labelId="demo-location-label"
            id="demo-location"
            name="assign_to"
            value={targetDetails?.assign_to || ''}
            onChange={(e) => handelValueChange(e)}
            input={<OutlinedInput label="Assign To" />}
            MenuProps={MenuProps}
          >
            {subusers.map((user) => (
              <MenuItem
                key={user?.id}
                value={user?.firstName + " " + user?.lastName}
              >
                {user?.firstName + " " + user?.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* LOCATION */}
      {targetDetails?.assign_to && (
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="demo-location-label">Locations</InputLabel>
          <Select
            labelId="demo-location-label"
            id="demo-location"
            value={targetDetails?.location || ''}
            name="location"
            onChange={(e) => handelValueChange(e)}
            input={<OutlinedInput label="Select Location" />}
            MenuProps={MenuProps}
          >
            {locations.map((location) => (
              <MenuItem key={location?.id} value={location?.location}>
                {location?.location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Stack direction="row" mt={3}>
        <Button
          variant="outlined"
          sx={{ mr: 1, color: "#33ae5e" }}
          onClick={() => {
            navigate("/targets")
          } }
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={handelNextStep}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default AddTarget;
