import axios from 'axios';
import config from '../../config/config.json';

const login = async (token) => {
  try{
    const response = await axios.get(`${config.API_URL}carbon/auth/verifyLogin`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }catch(err) {
    throw new Error(err.response.data.message);
  }
    
}


export const loginService = {
    login
};
  