/** @format */

import React from "react";
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styles from "./assetManagement.module.css";
import theme from "../theme";
import { styled } from "@mui/material/styles";
import { esgTargets } from "../../constant/assetManagement";
import InputAdornment from "@mui/material/InputAdornment";
import { Info } from "@mui/icons-material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "24px",
  },
}));
const rowsTitle = [
  "Energy Consumption",
  "Renewable Energy Use",
  "GHG Emissions",
  "Water Consumptions",
  "Waste diverted from landfill",
  "Building Certifications",
  "Data Coverage",
];
const EsgTargetsUnderPlan = () => {
  return (
    <div className={styles.container}>
      <TableContainer
        component={Paper}
        elevation={0}
        className={styles.centerTable}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{ bgcolor: theme.palette.esgblueblack.main }}
              className={styles.tableHeadRow}
            >
              <TableCell></TableCell>
              {esgTargets.map((item, index) => (
                <TableCell
                  className={styles.tableHeadCell}
                  key={item.title}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.title}

                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          color="inherit"
                          variant="h6"
                          className={styles.tooltipTitle}
                          mb={2}
                        >
                          Explanation
                        </Typography>
                        <Typography
                          variant="p"
                          className={styles.tooltipExplaination}
                        >
                          {item.tooltip}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    {item.tooltip && (
                      <IconButton size="small">
                        <Info
                          sx={{
                            color: "white",
                            fontSize: "14px",
                            marginLeft: "16px",
                          }}
                        />
                      </IconButton>
                    )}
                  </HtmlTooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsTitle.map((item, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Checkbox />
                </TableCell>
                {/* {renderSelectFields().map((selectField) => selectField)} */}
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  className={styles.tableTypography}
                >
                  <Typography variant="p" className={styles.tableTypography}>
                    {item}
                  </Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <TextField type="number" size="small" />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <TextField
                    type="number"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <TextField type="number" size="small" />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <TextField type="number" size="small" />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <TextField
                    type="number"
                    size="small"
                    sx={{ width: "100%" }}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Checkbox />
              </TableCell>
              <TableCell>
                <TextField placeholder="other" size="small" />
              </TableCell>
              <TableCell className={styles.tableBodyCell}>
                <TextField type="number" size="small" />
              </TableCell>
              <TableCell className={styles.tableBodyCell}>
                <TextField
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </TableCell>
              <TableCell className={styles.tableBodyCell}>
                <TextField type="number" size="small" />
              </TableCell>
              <TableCell className={styles.tableBodyCell}>
                <TextField type="number" size="small" />
              </TableCell>
              <TableCell className={styles.tableBodyCell}>
                <TextField type="number" size="small" sx={{ width: "100%" }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EsgTargetsUnderPlan;
