/** @format */

// import { ExpandMore } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
// import { useLocation } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabComponent = ({
  tab1Name,
  tab2Name,
  tab1Width = "230px",
  tab2Width = "230px",
  value,
  handleChange,
}) => {
  // console.log({ value });
  // const location = useLocation();
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        width: "100%",
        margin: "0 auto",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab
          label={
            // location.pathname.includes("data-vault") ? (
            //   <span>
            //     {tab1Name}
            //     <IconButton size="small" color={value === 0 ? "primary" : ""}>
            //       <ExpandMore />
            //     </IconButton>
            //   </span>
            // ) : (
            tab1Name
            // )
          }
          {...a11yProps(0)}
          sx={{ width: tab1Width }}
        />
        <Tab
          label={
            // location.pathname.includes("data-vault") ? (
            //   <span>
            //     {tab2Name}
            //     <IconButton size="small">
            //       <ExpandMore color={value === 1 ? "primary" : ""} />
            //     </IconButton>
            //   </span>
            // ) : (
            tab2Name
            // )
          }
          {...a11yProps(1)}
          sx={{ width: tab2Width }}
        />
      </Tabs>
    </Box>
  );
};

export default TabComponent;
