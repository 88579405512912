import axios from "axios";
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';

const PREFIX = 'carbon/energy/';

export const addEnergyValues = async (payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.post(`${config.API_URL + PREFIX}addEnergyConsumption`, payload, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const fetchEnergyValues = async (fy_start, fy_end, limit, page) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}fetchEnergyConsumptionList?fy_start=${fy_start}&fy_end=${fy_end}&limit=${limit}&page=${page}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const fetchEnergyDetails = async (id) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}fetchEnergyConsumptionDetail/${id}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const updateEnergyValues = async (id, payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.put(`${config.API_URL + PREFIX}updateEnergyConsumption/${id}`, payload, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const deleteEnergyValues = async (id) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.delete(`${config.API_URL + PREFIX}deleteEnergyConsumption/${id}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};