/** @format */

import React, { useState } from "react";
import { Chip, Stack, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import styles from "./scopeCardItem.module.css";
import { useNavigate } from "react-router-dom";

const ScopeCardItem = ({ entity, deleteRow, type }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handelDelete = async() => {
    handleClose();
    await deleteRow(entity?.id);
  }



  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        bgcolor: "#F6F7FB",
        padding: "16px",
        borderRadius: "8px",
        margin: "16px 0",
      }}
    >
      <Stack>
        <Typography className={styles.cardTitle}>
          {entity.location}
        </Typography>
        <Stack direction="row" gap="6px" className={styles.chipCard}>
          {entity.categories.map((item, index) => (
            <Chip
              label={item}
              variant="outlined"
              className={styles.scopeChip}
              key={index}
            />
          ))}
        </Stack>
      </Stack>
      <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate(`/scope/update-scope${type}-details/${entity?.id}`)}> View/Update</MenuItem>
          <MenuItem onClick={handelDelete}>Delete</MenuItem>
        </Menu>
    </Stack>
  );
};

export default ScopeCardItem;
