/** @format */

import { Stack } from "@mui/material";
import React from "react";
import BarChart from "../charts/BarChart";

import { PieData, UserData } from "../../Data";
import DoughnutChart from "../charts/Doughnut";

const EnergyAnalytics = () => {
  const userData = {
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "User Gain",
        type: "line",
        data: UserData.map((data) => data.userGain),
        borderColor: "#6DB3B5",
        fill: true,
        tension: 0,
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      {
        label: false,
        type: "bar",
        data: UserData.map((data) => data.userGain),
        backgroundColor: "#165BAA",
        barThickness: 32,
      },
    ],
  };
  const pieData = {
    labels: PieData.map((data) => data.year),
    datasets: [
      {
        label: "User Lost",
        type: "doughnut",
        data: PieData.map((data) => data.userLost),
        backgroundColor: ["#A155B9", "#165BAA", "#F765A3", "#16BFD6"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Stack gap="24px">
      <BarChart
        chartData={userData}
        yAxisTitle="Energy Consumption [MWhs]"
        chartTitle="Yearly Energy Consumption"
        aspectRatio={5}
      />
      <DoughnutChart
        chartData={pieData}
        type="Total Energy Consumption"
        chartHeight={300}
      />
    </Stack>
  );
};

export default EnergyAnalytics;
