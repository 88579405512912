/** @format */

import { Card, Stack, Switch, Typography } from "@mui/material";
import "./integrationItem.css";
import React from "react";

const IntegrationItem = ({ title, content }) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <Card sx={{ padding: "16px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="card-header"
        paddingBottom={0}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="mdi-light:image">
            <path
              id="Vector"
              d="M5 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V19C21 19.7956 20.6839 20.5587 20.1213 21.1213C19.5587 21.6839 18.7956 22 18 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V6C2 5.20435 2.31607 4.44129 2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3ZM5 4C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V17.59L7.29 13.29L9.79 15.79L14.79 10.79L20 16V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H5ZM9.79 17.21L7.29 14.71L3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17.41L14.79 12.21L9.79 17.21ZM7.5 6C8.16304 6 8.79893 6.26339 9.26777 6.73223C9.73661 7.20107 10 7.83696 10 8.5C10 9.16304 9.73661 9.79893 9.26777 10.2678C8.79893 10.7366 8.16304 11 7.5 11C6.83696 11 6.20107 10.7366 5.73223 10.2678C5.26339 9.79893 5 9.16304 5 8.5C5 7.83696 5.26339 7.20107 5.73223 6.73223C6.20107 6.26339 6.83696 6 7.5 6ZM7.5 7C7.10218 7 6.72064 7.15804 6.43934 7.43934C6.15804 7.72064 6 8.10218 6 8.5C6 8.89782 6.15804 9.27936 6.43934 9.56066C6.72064 9.84196 7.10218 10 7.5 10C7.89782 10 8.27936 9.84196 8.56066 9.56066C8.84196 9.27936 9 8.89782 9 8.5C9 8.10218 8.84196 7.72064 8.56066 7.43934C8.27936 7.15804 7.89782 7 7.5 7Z"
              fill="#0F333D"
            />
          </g>
        </svg>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Stack>
      <Typography variant="h6" className="card-title">
        {title}
      </Typography>
      <Typography variant="p" className="card-content">
        {content}
      </Typography>
    </Card>
  );
};

export default IntegrationItem;
