import axios from "axios";
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';

const PREFIX = 'carbon/settings/';

export const fethCompanyConfig = async () => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}getUserConfig`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};


export const fethLocations = async () => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}getLocations`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const createLocations = async (payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.post(`${config.API_URL + PREFIX}addLocation`, payload, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const updateLocation = async (id, payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.put(`${config.API_URL + PREFIX}updateLocation/${id}`, payload, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const deleteLocation = async (id) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.delete(`${config.API_URL + PREFIX}deleteLocation/${id}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};