/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: 0,
};

export const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    SETTAB: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { SETTAB } = assetSlice.actions;

export default assetSlice.reducer;
