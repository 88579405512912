import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./addWasteDetails.module.css";
import theme from "../../components/theme";
import { fethLocations, fethLocationsValues } from '../../services/private/config/config';
import moment from "moment";
import { saveWasteConsumption, saveWasteConsumptionDetail, updateWasteConsumption } from '../../services/private/waste/WasteService';
import { generateArray } from '../../utils/UniversalFunction';
import { successToast,errorToast } from "../../utils/alerts";
const nonHazardousTable = [
  {
    sub_title: "Recycled",
    quantity: null,
    specific_consumption: null,
    reference_value: null,
    deviation: null,
  },
  {
    sub_title: "Disposal",
    quantity: null,
    specific_consumption: null,
    reference_value: null,
    deviation: null,
  },
  {
    sub_title: "Scrap",
    quantity: null,
    specific_consumption: null,
    reference_value: null,
    deviation: null,
  },
];
const hazardousTable = [
  {
    sub_title: "Recycled",
    quantity: null,
    specific_consumption: null,
    reference_value: null,
    deviation: null,
  },
  {
    sub_title: "Disposal",
    quantity: null,
    specific_consumption: null,
    reference_value: null,
    deviation: null,
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddWasteDetails = ({details, type}) => {
  const navigate = useNavigate();

  const [wasteData, setWasteData] = useState({
    id: details?.id,
    selectedAsset: details?.location_id,
    unit: details?.unit,
    type: details?.type
  });
  const [locations, setLocations] = useState([]);
  const [entity, setEntity] = useState(null);
  const [submitted, setSubmitted] = useState(false)
  const [values, setValues] = useState(details?.records || []);
  const [nonHazard, setNonHazard] = useState(details?.details?.nonHazard || [...nonHazardousTable]);
  const [hazard, setHazard] = useState(details?.details?.hazard || [...hazardousTable]);


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setWasteData({ ...wasteData, [name]: value });
  };

  const requestLocation = async() => {
    try{
      const entity = await fethLocations();
      setLocations(entity.data);
    }catch(error) {
      errorToast(error.message);
    }
  }

  const requestMonths = async () => {
    try {
      if (entity) {
        const response = await fethLocationsValues(entity);
        setValues(response.data.entities);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const handelEntityChange = async(e) => {

    const { name, value } = e.target;
    const entity = name.split('-');
    const month = entity[0];
    const year = entity[1];
    const index = values.findIndex(item => item.months === month && item.years === year);
    let tempValues = [...values];
    tempValues[index].values = value;
    setValues(tempValues);

  }

  const handelDetailChange = async(e, index) => {

    const {name, value, id} = e.target;
    if(id === 'Not hazardous') {
      let tempArr = [...nonHazard];
      tempArr[index][name] = value;
      setNonHazard(tempArr);
    }else{
      let tempArr = [...hazard];
      tempArr[index][name] = value;
      setHazard(tempArr);
    }
  }

  const removeDetails = async() => {

    const hazardss = await hazardousTable.map((item) => {
      return   {
        sub_title: item.sub_title,
        quantity: null,
        specific_consumption: null,
        reference_value: null,
        deviation: null,
      }
    });
    await setHazard(hazardss)

    const nonHazardss = await nonHazardousTable.map((item) => {
      return   {
        sub_title: item.sub_title,
        quantity: null,
        specific_consumption: null,
        reference_value: null,
        deviation: null,
      }
    });    
    await setNonHazard(nonHazardss)
    
  }

const handelSave = async(e) => {
  e.preventDefault();

  setSubmitted(true);
  let isValid=true;
  values.forEach((item) => {
    if(item.values === '' || item.values === null || item.values === undefined) {
      isValid = false
    }
  });

  if(!isValid) {
    errorToast('All fields are required!');
  }else{
    let formattedArray = await generateArray(values);
    // eslint-disable-next-line
    let payload = {
      location_id: `${wasteData.selectedAsset}`,
      unit: wasteData.unit,
      type: wasteData.type,
      years: formattedArray.years,
      months: formattedArray.months,
      values: formattedArray.values,
    }

    try{

      if(type === 'update') {

        let updatePayload = {...payload};

        let hazardArr = hazard.map((item) => {
          return { ...item, title: 'Hazardous'}
        });
        let nonHazardArr = nonHazard.map((item) => {
          return { ...item, title: 'Not hazardous'}
        });
        let combinedArr = await hazardArr.concat(nonHazardArr);
        updatePayload.consumption = combinedArr;
        await updateWasteConsumption(wasteData?.id, updatePayload);
        successToast("Updated Successfully");
        navigate('/waste');
      }else{
        const apiResponse = await saveWasteConsumption(payload);

        let hazardArr = hazard.map((item) => {
          return { ...item, title: 'Hazardous'}
        });
        let nonHazardArr = nonHazard.map((item) => {
          return { ...item, title: 'Not hazardous'}
        });

        let combinedArr = await hazardArr.concat(nonHazardArr);
        let detailsPayload = {
          waste_id: `${apiResponse?.data}`,
          consumption: combinedArr
        }
        await saveWasteConsumptionDetail(detailsPayload);
        successToast("Created Successfully");
        navigate('/waste');
      }
    }catch(error){
      errorToast(error.message);
    }
  }

}
  useEffect(() => {
    requestMonths();
    if(type !== 'update'){
      removeDetails();
    }
    // eslint-disable-next-line
  }, [entity]);

  useEffect(() => {
    requestLocation();
  }, []);

  return (
    <form name="wasteData">
      <Stack
        direction="row"
        gap="16px"
        alignItems="center"
        className={styles.pageTitle}
      >
        <Box className={styles.backArrow}>
          <ArrowBack onClick={() => navigate("/waste")} />
        </Box>
        <Stack direction="row" gap="16px" alignItems="center">
          <Typography className={styles.title}>Waste Consumption</Typography>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99935 1.66663C5.39935 1.66663 1.66602 5.39996 1.66602 9.99996C1.66602 14.6 5.39935 18.3333 9.99935 18.3333C14.5993 18.3333 18.3327 14.6 18.3327 9.99996C18.3327 5.39996 14.5993 1.66663 9.99935 1.66663ZM10.8327 14.1666H9.16602V12.5H10.8327V14.1666ZM10.8327 10.8333H9.16602V5.83329H10.8327V10.8333Z"
              fill="#0F333D"
            />
          </svg>
        </Stack>
      </Stack>
      <div className={styles.inputFieldContainer}>
        <FormControl>
          <InputLabel id="demo-category-label">Select Asset</InputLabel>
          <Select
            labelId="demo-category-label"
            name="selectedAsset"
            value={wasteData?.selectedAsset || ''}
            onChange={handleChange}
            input={<OutlinedInput label="Select Asset" />}
            MenuProps={MenuProps}
            error={submitted && !wasteData.selectedAsset }
          >
          {locations.map((location) => (
            <MenuItem 
              key={location?.id}
              value={location?.id}
              onClick={() => setEntity(location?.id)}
            >
              {location?.location}
            </MenuItem>
          ))}
          </Select>
        </FormControl>
        <TextField 
          label="Unit"
          name="unit"
          value={wasteData.unit || ''} 
          onChange={handleChange}
          error={submitted && !wasteData.unit }
          helperText={submitted && !wasteData.unit ? "Required field" : ''}
        />
      </div>
      <FormControl sx={{ width: "100%", marginBottom: "32px" }}>
        <InputLabel id="demo-category-label">Type of recycling</InputLabel>
        <Select
          labelId="demo-category-label"
          name="type"
          value={wasteData?.type || ''}
          onChange={handleChange}
          input={<OutlinedInput label="Type of recycling" />}
          MenuProps={MenuProps}
          error={submitted && !wasteData.type }
        >
          <MenuItem  value={'0'}>
            Recycled
          </MenuItem>
          <MenuItem  value={'1'}>
            Disposal
          </MenuItem>
          <MenuItem  value={'2'}>
            Not Recycled
          </MenuItem>
          <MenuItem  value={'3'}>
            Scrap
          </MenuItem>
        </Select>
      </FormControl>
      <Typography className={styles.enterMonthly}>
        Enter your monthly value
      </Typography>
      {
        <Box className={styles.gridContainer}>
           {values.map((item, index) => (
              <TextField
                label={moment(item.months, 'MMMM').format('MMM') + "-" + item.years}
                sx={{ width: "100%" }}
                name={`${item.months}-${item.years}`}
                type="number"
                value={item.values}
                onChange={handelEntityChange}
                key={index}
              />
            ))}
        </Box>
      }
      <TableContainer component={Paper} sx={{ margin: "32px 0" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: theme.palette.esgblueblack.main }}>
              <TableCell className={styles.tableHead}>waste in 2022</TableCell>
              <TableCell className={styles.tableHead}>
                type of recycling
              </TableCell>
              <TableCell className={styles.tableHead}>quantity [m3]</TableCell>
              <TableCell className={styles.tableHead}>
                specific consumption [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>
                reference value from 2021 [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>deviation [%] </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
            <TableCell className={styles.tableCell} rowSpan={4}>
              Not hazardous
            </TableCell>
            </TableRow>
            {nonHazard.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={styles.tableCell}>{item.sub_title}</TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.quantity || ''}
                    name="quantity"
                    id="Not hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.specific_consumption || ''}
                    name="specific_consumption"
                    id="Not hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.reference_value || ''}
                    name="reference_value"
                    id="Not hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.deviation || ''}
                    name="deviation"
                    id="Not hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
              <TableRow>
            <TableCell className={styles.tableCell} rowSpan={3}>
              Hazardous
            </TableCell>
            </TableRow>
            {hazard.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={styles.tableCell}>{item.sub_title}</TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.quantity || ''}
                    name="quantity"
                    id="Hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.specific_consumption || ''}
                    name="specific_consumption"
                    id="Hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.reference_value || ''}
                    name="reference_value"
                    id="Hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    value={item.deviation || ''}
                    name="deviation"
                    id="Hazardous"
                    type="number"
                    onChange={(e) => handelDetailChange(e, index)}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end" gap="16px">
        <Button variant="outlined" onClick={() => navigate("/waste")}>
          Cancel
        </Button>
        <Button variant="contained" type="submit" className={styles.btnNext} onClick={(e)=> handelSave(e)}>
          {type === 'update' ? 'Update' : 'Save'}
        </Button> 
      </Stack>
    </form>
  );
};

export default AddWasteDetails;
