/** @format */

import React, { useState } from "react";
import DataVault from "../DataVault";
import { Stack, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import styles from "../governance/leadershipCommitments.module.css";
import { ExpandMore } from "@mui/icons-material";
import TcfdAccordion from "./TcfdAccordion";
const governanceData = [
  {
    id: 1,
    accordionSummary:
      "Describe the boards oversight of climate-related risks and opportunities",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    id: 2,
    accordionSummary:
      "Describe managements role in assessing and managing climate related risks and opportunities",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];
const strategyData = [
  {
    id: 1,
    accordionSummary:
      "Describe the climate related risks and opportunities the organisation has identified over the short term, medium and long term ",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    id: 2,
    accordionSummary:
      "Describe the impact of climate related risks and opportunities on the organisations business, strategy and financial planning ",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    id: 3,
    accordionSummary:
      "Describe the resilience of the organisations strategy, taking into consideration different climate related scenarios, including a 2 °C or lower scenario ",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];
const riskManagementData = [
  {
    id: 1,
    accordionSummary:
      "Describe the organisations processes for identifying and assessing climate related risks ",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    id: 2,
    accordionSummary:
      "Describe the organisations process for managing climate related risks ",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    id: 3,
    accordionSummary:
      "Describe how processes for identifying, assessing and managing climate related risks are integrated into the organisations overall risk management ",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];
const metricsData = [
  {
    id: 1,
    accordionSummary:
      "Disclose the metrics used by the organisation to assess climate related risks and opportunities in line with its strategy and risk management process",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    id: 2,
    accordionSummary:
      "Disclose Scope 1, Scope 2 and if appropriate Scope 3 greenhouse gas emissions and the related risks ",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    id: 3,
    accordionSummary:
      "Describe the targets used by the organisation to manage climate related risks and opportunities and performance against targets",
    accordionDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];

const Tcfd = () => {
  const [value, setValue] = useState("1");
  // eslint-disable-next-line
  const [answered, setAnswered] = useState(0);
  const handleInnerTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DataVault>
      <Stack gap="16px" className={styles.leadershipContainer}>
        <Typography variant="h5" className={styles.pageHeading}>
          TCFD
        </Typography>
        <Typography variant="p" className={styles.explaination}>
          This will be a brief explanation of the Corporate ESG section. Lorem
          Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages,
        </Typography>
        <Stack>
          <Typography className={styles.questionCount} pb={1}>
            Questions Answered: {answered}/23
          </Typography>
          <TabContext value={value}>
            <TabList onChange={handleInnerTab}>
              <Tab label="1.	Governance" value="1">
                <ExpandMore />
              </Tab>
              <Tab label="2. Strategy" value="2" />
              <Tab label="3. Risk Management" value="3" />
              <Tab
                label="4. Metrics and Targets"
                value="4"
                sx={{ maxWidth: "630px" }}
              />
            </TabList>
            <TabPanel value="1">
              <TcfdAccordion data={governanceData} />
            </TabPanel>
            <TabPanel value="2">
              <TcfdAccordion data={strategyData} />
            </TabPanel>
            <TabPanel value="3">
              <TcfdAccordion data={riskManagementData} />
            </TabPanel>
            <TabPanel value="4">
              <TcfdAccordion data={metricsData} />
            </TabPanel>
          </TabContext>
        </Stack>
      </Stack>
    </DataVault>
  );
};

export default Tcfd;
