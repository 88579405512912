/** @format */

import React from "react";
import { Box } from "@mui/material";
import ScopeList from "../../components/scopes/ScopeList";

const Scope2 = () => {
  return (
    <Box>
      <ScopeList type={2} />
    </Box>
  );
};

export default Scope2;
