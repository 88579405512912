/** @format */

import React from "react";
import { Box } from "@mui/material";
import ScopeList from "../../components/scopes/ScopeList";

const Scope1 = () => {
  return (
    <Box>
      <ScopeList type={1} />
    </Box>
  );
};

export default Scope1;
