/** @format */

import React, { useState } from "react";
import { Button, Tab, Stack, TextField, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import styles from "./assetManagement.module.css";
// import BuildingCertification from "./BuildingCertification";
import ScrollableTable from "./ScrollableTable";
import {
  buildingCertification,
  efficiencyMeasures,
  energy,
  ghg,
  waste,
  water,
} from "../../constant/assetManagement";

const SustainabilityReporting = () => {
  const [value, setValue] = useState("1");
  const handleInnerTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack className={styles.container} gap={2} p={3}>
      <Typography variant="h5" className={styles.pageTitle}>
        Sustainability Reporting
      </Typography>
      <Typography variant="body" className={styles.description}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages,
      </Typography>
      <TabContext value={value}>
        <TabList onChange={handleInnerTab} sx={{ padding: "24px 0" }}>
          <Tab label="1.	building certifications" value="1"></Tab>
          <Tab label="2.	efficiency measures" value="2" />
          <Tab label="3.	energy" value="3" />
          <Tab label="4.	water" value="4" />
          <Tab label="5.	ghg" value="5" />
          <Tab label="6.	waste" value="6" />
        </TabList>
        {value !== "1" && (
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              size="small"
            >
              add new asset
            </Button>
            <TextField
              variant="outlined"
              label="Search"
              placeholder="Name, email, etc..."
              size="small"
            />
          </Stack>
        )}
        <TabPanel value="1" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={buildingCertification}
            headCellPadding={45}
            colLength={13}
            actionCellPadding={45}
            cellBorder="1px solid #e8ebf2;"
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={efficiencyMeasures}
            colLength={9}
            options={["True", "False", "N/A"]}
          />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={energy}
            colLength={62}
            headCellPadding={45}
            actionCellPadding={103}
            maxWidth={650}
            cellBorder="1px solid #e8ebf2;"
            fixedLeftHeaderTable={true}
          />
        </TabPanel>
        <TabPanel value="4" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={water}
            colLength={23}
            headCellPadding={30.7}
            actionCellPadding={74.7}
            maxWidth={650}
            cellBorder="1px solid #e8ebf2;"
            fixedLeftHeaderTable={true}
          />
        </TabPanel>
        <TabPanel value="5" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={ghg}
            colLength={15}
            headCellPadding={30.7}
            actionCellPadding={74.7}
            maxWidth={650}
            cellBorder="1px solid #e8ebf2;"
            fixedLeftHeaderTable={true}
          />
        </TabPanel>
        <TabPanel value="6" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={waste}
            colLength={11}
            headCellPadding={30.7}
            actionCellPadding={74.7}
            maxWidth={650}
            cellBorder="1px solid #e8ebf2;"
            fixedLeftHeaderTable={true}
          />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default SustainabilityReporting;
