import axios from 'axios';
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';
const PREFIX = "carbon/water/";

export const saveWaterConsumption = async(payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.post(`${config.API_URL + PREFIX}addWaterConsumption`, payload, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const updateWaterConsumption = async(id, payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.put(`${config.API_URL + PREFIX}updateWaterConsumption/${id}`, payload, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const saveWaterConsumptionDetail = async(payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.post(`${config.API_URL + PREFIX}addWaterConsumptionDetail`, payload, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const requestWaterConsumption = async(fy_start, fy_end, limit, page) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}fetchWaterConsumptionList?fy_start=${fy_start}&fy_end=${fy_end}&limit=${limit}&page=${page}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const requestWaterConsumptionDetails = async (id) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}fetchWaterConsumptionDetail/${id}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const deleteWaterConsumption = async(id) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.delete(`${config.API_URL + PREFIX}deleteWaterConsumption/${id}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};