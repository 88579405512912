/** @format */

import { DeleteOutline, Info } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import theme from "../theme";
import styles from "./assetManagement.module.css";
import { styled } from "@mui/material/styles";
import EditIcon from "../../assets/icons/EditIcon";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "24px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const fixedLeftHeader = [
  [
    { title: "", colSpan: 1, rowSpan: 4 },

    {
      title: "Asset Characteristics",
      colSpan: 2,
    },
    { title: "Reporting Characteristics", colSpan: 1 },
  ],
  [
    { title: "Asset Name", colSpan: 1 },
    { title: "Property Type", colSpan: 1 },
    { title: "Reporting Year", colSpan: 1 },
  ],
];
const ScrollableTable = ({
  tableContent,
  options,
  headCellPadding = 16,
  colLength,
  actionCellPadding = 16,
  maxWidth = 220,
  cellBorder = "none",
  fixedLeftHeaderTable = false,
}) => {
  // const [addNewField, setAddNewField] = useState(0);
  const handleChange = (event, index) => {
    // const newValue = event.target.value;
    // console.log(`Dropdown at index ${index} changed to: ${newValue}`);
  };
  const renderSelectFields = () => {
    const selectFields = [];
    for (let i = 0; i < colLength; i++) {
      selectFields.push(
        <TableCell
          key={`cell-${i}`}
          className={styles.tableBodyCell}
          align="center"
          sx={{ padding: fixedLeftHeaderTable ? "6.6px 16px" : "16px 16px" }}
        >
          <FormControl
            sx={{ minWidth: "150px", width: "100%", fontFamily: "inherit" }}
          >
            <Select
              labelId={`demo-country-label-${i}`}
              id={`demo-country-${i}`}
              name={`type-${i}`}
              onChange={(e, i) => handleChange(e, i)}
              MenuProps={MenuProps}
              size="small"
              value=""
            >
              {options?.map((item) => (
                <MenuItem key={item} value={item} id={`input-dropdown-${i}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
      );
    }

    return selectFields;
  };
  return (
    <div className={styles.container}>
      <Stack direction="row">
        <TableContainer
          component={Paper}
          sx={{ maxWidth: maxWidth + "px" }}
          className={styles.leftTable}
        >
          <Table>
            {fixedLeftHeaderTable ? (
              <TableHead>
                {fixedLeftHeader.map((itemTable, index) => (
                  <TableRow
                    sx={{ bgcolor: theme.palette.esgblueblack.main }}
                    className={styles.tableHeadRow}
                    key={index}
                  >
                    {itemTable.map((item, i) => (
                      <TableCell
                        className={styles.tableHeadCell}
                        sx={{
                          padding: `${headCellPadding}px 16px`,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          borderRight: cellBorder,
                        }}
                        key={i}
                        colSpan={item.colSpan}
                        rowSpan={item.rowSpan}
                        align="center"
                      >
                        {item.title}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{ bgcolor: theme.palette.esgblueblack.main }}
                  className={styles.tableHeadRow}
                >
                  <TableCell
                    className={styles.tableHeadCell}
                    sx={{ padding: `${headCellPadding}px 16px` }}
                  ></TableCell>
                  <TableCell
                    className={styles.tableHeadCell}
                    sx={{ padding: `${headCellPadding}px 16px` }}
                  >
                    Asset Name
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {fixedLeftHeaderTable ? (
                <>
                  <TableRow>
                    <TableCell rowSpan={2}>
                      <Checkbox />
                    </TableCell>
                    <TableCell className={styles.tableBodyCell} rowSpan={2}>
                      <TextField size="small" />
                    </TableCell>
                    <TableCell
                      className={styles.tableBodyCell}
                      rowSpan={2}
                      sx={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography variant="p" size="small">
                        Office: corporate: Low-rise office
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.tableBodyCell} rowSpan={1}>
                      <Typography variant="p" size="small">
                        2020
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableBodyCell} rowSpan={1}>
                      <Typography variant="p" size="small">
                        2021
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell className={styles.tableBodyCell}>
                    <TextField size="small" />
                  </TableCell>
                </TableRow>
              )}

              {fixedLeftHeaderTable ? (
                <>
                  <TableRow>
                    <TableCell rowSpan={2}>
                      <Checkbox />
                    </TableCell>
                    <TableCell className={styles.tableBodyCell} rowSpan={2}>
                      <TextField size="small" />
                    </TableCell>
                    <TableCell
                      className={styles.tableBodyCell}
                      rowSpan={2}
                      sx={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography variant="p" size="small">
                        Office: corporate: Low-rise office
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.tableBodyCell} rowSpan={1}>
                      <Typography variant="p" size="small">
                        2020
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableBodyCell} rowSpan={1}>
                      <Typography variant="p" size="small">
                        2021
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell className={styles.tableBodyCell}>
                    <TextField size="small" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className={styles.centerTable}
        >
          <Table>
            <TableHead>
              {tableContent.map((headRowTable, index) => (
                <TableRow
                  sx={{ bgcolor: theme.palette.esgblueblack.main }}
                  className={styles.tableHeadRow}
                  key={index}
                >
                  {headRowTable.map((item, index) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={index}
                      sx={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        borderRight: cellBorder,
                      }}
                      colSpan={item.colSpan}
                      rowSpan={item.rowSpan}
                      align="center"
                    >
                      {item.title}

                      {item.tooltip && (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                color="inherit"
                                variant="h6"
                                className={styles.tooltipTitle}
                                mb={2}
                              >
                                Explanation
                              </Typography>
                              <Typography
                                variant="p"
                                className={styles.tooltipExplaination}
                              >
                                {item.tooltip}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <IconButton size="small">
                            <Info
                              sx={{
                                color: "white",
                                fontSize: "14px",
                                marginLeft: "16px",
                              }}
                            />
                          </IconButton>
                        </HtmlTooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              <TableRow>
                {renderSelectFields().map((selectField) => selectField)}
              </TableRow>
              <TableRow>
                {renderSelectFields().map((selectField) => selectField)}
              </TableRow>
              {fixedLeftHeaderTable && (
                <>
                  <TableRow>
                    {renderSelectFields().map((selectField) => selectField)}
                  </TableRow>
                  <TableRow>
                    {renderSelectFields().map((selectField) => selectField)}
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          // elevation={0}
          sx={{ maxWidth: "120px" }}
          className={styles.rightTable}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{ bgcolor: theme.palette.esgblueblack.main }}
                className={styles.tableHeadRow}
              >
                <TableCell
                  className={styles.tableHeadCell}
                  align="right"
                  sx={{ padding: `${actionCellPadding}px 16px` }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={styles.tableBodyCell}
                  sx={{
                    padding: fixedLeftHeaderTable ? "33.7px 16px" : "16px 16px",
                  }}
                >
                  <Stack direction="row" gap={1} justifyContent="flex-end">
                    <IconButton size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ color: theme.palette.esgblueblack.main }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={styles.tableBodyCell}
                  sx={{
                    padding: fixedLeftHeaderTable ? "33.7px 16px" : "16px 16px",
                  }}
                >
                  <Stack direction="row" gap={1} justifyContent="flex-end">
                    <IconButton size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ color: theme.palette.esgblueblack.main }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  );
};

export default ScrollableTable;
