/** @format */

import React from "react";
import {
  Box,
  Card,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./assetManagement.module.css";
import EsgTargetsCard from "../UI/EsgTargetsCard";
import theme from "../theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StackedBarChart from "../charts/StackedBarChart";

const EsgTargets = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState("Weekly");

  const handleChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <Stack className={styles.container} gap={2} p={3}>
      <Typography variant="h5" className={styles.pageTitle}>
        ESG Targets
      </Typography>
      <Typography variant="body" className={styles.description}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages,
      </Typography>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <EsgTargetsCard
          title="Energy Consumption"
          statistics="308,402"
          description="Nemo enim ipsamvoluptatem"
        />
        <EsgTargetsCard
          title="Renewable Energy Use"
          statistics="9,540"
          description="Ut enim ad"
        />
        <EsgTargetsCard
          title="GHC Emissions"
          statistics="8,511"
          description="Quis autem"
        />
        <EsgTargetsCard
          title="Water Consumption"
          statistics="10,584,485"
          description="Nam Libereo"
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <TableContainer
          style={{ width: "100%", maxWidth: "1007px" }}
          component={Paper}
          elevation={0}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{ bgcolor: theme.palette.esgblueblack.main }}
                className={styles.tableHeadRow}
              >
                <TableCell className={styles.tableHeadCell}></TableCell>
                <TableCell className={styles.tableHeadCell}>Area</TableCell>
                <TableCell
                  className={styles.tableHeadCell}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  Target Type
                </TableCell>
                <TableCell
                  className={styles.tableHeadCell}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  Long-Term Target (%)
                </TableCell>
                <TableCell
                  className={styles.tableHeadCell}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  Baseline Year
                </TableCell>
                <TableCell
                  className={styles.tableHeadCell}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  End Year
                </TableCell>
                <TableCell className={styles.tableHeadCell}>
                  Are These Targets Communicated Externally?
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography variant="p">Energy Consumption</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography
                    variant="p"
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Energy Consumption
                  </Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography variant="p">Energy Consumption</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography variant="p">Energy Consumption</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography variant="p">Energy Consumption</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography variant="p">Energy Consumption</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography variant="p">Energy Consumption</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell className={styles.tableBodyCell}>
                  <Checkbox />
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <Typography variant="p">Energy Consumption</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {[].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell className={styles.tableBodyCell}>21%</TableCell>
                <TableCell className={styles.tableBodyCell}>2019</TableCell>
                <TableCell className={styles.tableBodyCell}>2023</TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {/* <FormControl
                    sx={{
                      minWidth: "150px",
                      width: "100%",
                      fontFamily: "inherit",
                    }}
                  >
                    <Select
                      labelId={`demo-country-label`}
                      id={`demo-country`}
                      name={`type`}
                      onChange={(e, i) => handleChange(e, i)}
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {["Yes", "No", "N/A"].map((item) => (
                        <MenuItem key={item} value={item} id={`input-dropdown`}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Typography>Data</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Card
          style={{ width: "100%", maxWidth: "541px", padding: "16px" }}
          elevation={0}
          className={styles.chartContainer}
        >
          <Stack gap={0.5}>
            <Typography variant="h6" className={styles.chartTitle}>
              ESG Targets
            </Typography>
            <Box minWidth={15}>
              <Select
                labelId="demo-simple-select-label"
                value={selectedPeriod}
                onChange={handleChange}
                className={styles.dropdownContainer}
                IconComponent={KeyboardArrowDownIcon}
              >
                {["Weekly", "Monthly", "Annually"].map((period) => (
                  <MenuItem key={period} value={period} id="input-dropdown">
                    <Typography className={styles.dropdown}>
                      {period}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <StackedBarChart />
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};

export default EsgTargets;
