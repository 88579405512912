/** @format */
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#33AE5E",
    },
    esgblueblack: {
      main: "#0f333d",
    },
    esgorange: {
      main: "#EE7420",
    },
    esggray: {
      main: "#82959C",
    },
    esglightergray: {
      main: "#4F5367",
    },
  },
});

export default theme;
