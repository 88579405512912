/** @format */

import {
  Box,
  Typography,
  MenuItem,
  Menu,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./dataVault.module.css";
import { ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
// import Navbar from "../../components/UI/Navbar";

const DataVault = ({ children }) => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [activeTab, setActiveTab] = useState(false);
  const bordershow = (tabActive) => (tabActive ? "2px solid #33AE5E" : "none");
  const handleMenuOpen1 = (event) => {
    handleMenuClose2();
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };

  const handleMenuOpen2 = (event) => {
    handleMenuClose1();
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose2 = () => {
    setAnchorEl2(null);
  };

  // const handleMenuItemClick = (content) => {
  //   setSelectedContent(content);
  //   handleMenuClose1();
  //   handleMenuClose2();
  // };
  const handleMenuLeave1 = () => {
    setTimeout(() => {
      if (!anchorEl1) {
        handleMenuClose1();
      }
    }, 300); // Adjust the delay as needed to prevent flickering
  };

  const handleMenuLeave2 = () => {
    setTimeout(() => {
      if (!anchorEl2) {
        handleMenuClose2();
      }
    }, 300); // Adjust the delay as needed to prevent flickering
  };
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname.includes("leadership") ||
      location.pathname.includes("decision") ||
      location.pathname.includes("corporate")
    ) {
      setActiveTab(true);
    } else {
      setActiveTab(false);
    }
  }, [location.pathname]);

  return (
    <Box className={styles.dataVaultContainer}>
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "#F6F8FC",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: 10,
        }}
        elevation={0}
      >
        <Toolbar onMouseLeave={handleMenuLeave1}>
          <Button
            id="Governance-button"
            aria-controls="nav1-menu"
            aria-haspopup="true"
            onMouseOver={handleMenuOpen1}
            // onMouseLeave={handleMenuClose1}
            endIcon={<ExpandMore color={activeTab ? "primary" : "esggray"} />}
            sx={{
              padding: "9px 16px",
              cursor: "pointer",
              borderBottom: bordershow(activeTab),
              borderRadius: 0,
            }}
          >
            <Typography
              className={styles.navLinks}
              sx={{ color: activeTab ? "#33ae5e" : "#82959c" }}
            >
              Governance
            </Typography>
          </Button>
          <Menu
            id="Governance-menu"
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={handleMenuClose1}
            elevation={1}
            onMouseLeave={handleMenuLeave1}
          >
            <MenuItem
              onClick={() => navigate("/data-vault/leadership-commitments")}
              sx={{
                color: "#0f333d",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "157%",
                letterSpacing: "0.1px",
              }}
            >
              Leadership Commitments
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/data-vault/decision-making")}
              sx={{
                color: "#0f333d",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "157%",
                letterSpacing: "0.1px",
              }}
            >
              ESG Decision Making
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/data-vault/corporate-reporting")}
              sx={{
                color: "#0f333d",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "157%",
                letterSpacing: "0.1px",
              }}
            >
              Corporate ESG Reporting
            </MenuItem>
          </Menu>
        </Toolbar>
        <Toolbar onMouseLeave={handleMenuLeave2}>
          <Button
            id="basic-button"
            aria-controls="nav2-menu"
            aria-haspopup="true"
            onMouseOver={handleMenuOpen2}
            // onMouseLeave={handleMenuClose2}
            endIcon={<ExpandMore color={!activeTab ? "primary" : "esggray"} />}
            sx={{
              padding: "9px 16px",
              cursor: "pointer",
              borderBottom: bordershow(!activeTab),
              borderRadius: 0,
            }}
          >
            <Typography
              className={styles.navLinks}
              sx={{ color: !activeTab ? "#33ae5e" : "#82959c" }}
            >
              ESG Reporting
            </Typography>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={handleMenuClose2}
            elevation={1}
            onMouseLeave={handleMenuLeave2}
          >
            <MenuItem
              onClick={() => navigate("/data-vault/sdg")}
              sx={{
                color: "#0f333d",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "157%",
                letterSpacing: "0.1px",
              }}
            >
              Sustainable Development Goals
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/data-vault/tcfd")}
              sx={{
                color: "#0f333d",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "157%",
                letterSpacing: "0.1px",
              }}
            >
              TCFD
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/data-vault/reporting-intelligence")}
              sx={{
                color: "#0f333d",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "157%",
                letterSpacing: "0.1px",
              }}
            >
              ESG Reporting Intelligence
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {/* <Navbar firstLink="Governance" secondLink="ESG Reporting" /> */}
      <Box mt={1}>{children}</Box>
    </Box>
  );
};

export default DataVault;
