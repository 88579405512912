/** @format */
import React from "react";
import { useSelector } from "react-redux";
import HorizontalBarChart from "../components/charts/HorizontalBarChart";
import {
  Box,
  Paper,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./dashboard.module.css";
import Doughnut from "../components/charts/Doughnut";
import { PieData } from "../Data";

const pieData = {
  labels: PieData.map((data) => data.year),
  datasets: [
    {
      label: "User Lost",
      type: "doughnut",
      data: PieData.map((data) => data.userLost),
      backgroundColor: ["#A155B9", "#165BAA", "#F765A3", "#16BFD6"],
      borderWidth: 0,
    },
  ],
};
const table1Header = [
  "Emission Source",
  "Sum of 2022 Emissions (TCO2E)",
  "Sum of 2022 Emissions (KGCO2E)",
];
const table1Data = [
  { id: 1, col1: "Scope 1", col2: 77, col3: 76964, headBodyRow: true },
  {
    id: 2,
    col1: "Transport (Land & Sea)",
    col2: 77,
    col3: 76964,
    headBodyRow: false,
  },
  { id: 3, col1: "Scope 2", col2: 86, col3: 85880, headBodyRow: true },
  { id: 4, col1: "Electricity", col2: 86, col3: 85880, headBodyRow: false },
  { id: 5, col1: "Scope 3", col2: 4976, col3: 4975553, headBodyRow: true },
  {
    id: 6,
    col1: "Accommodations and facility ",
    col2: 17,
    col3: 19440,
    headBodyRow: false,
  },
  {
    id: 7,
    col1: "Clearing & chemical",
    col2: 17,
    col3: 19440,
    headBodyRow: false,
  },
  { id: 8, col1: "Electricity", col2: 17, col3: 19440, headBodyRow: false },
  {
    id: 9,
    col1: "Food and catering",
    col2: 17,
    col3: 19440,
    headBodyRow: false,
  },
  {
    id: 10,
    col1: "Machinery and vehicles",
    col2: 17,
    col3: 19440,
    headBodyRow: false,
  },
  {
    id: 11,
    col1: "Grand total",
    col2: 5623,
    col3: 533397,
    headBodyRow: false,
  },
];
const table2Header = ["Categories", "Scope", "Rating"];
const table2Data = [
  { id: 1, col1: "Transport (Land & Sea)", col2: "Scope 1", col3: "Neutral" },
  { id: 2, col1: "Machinery and vehicles", col2: "Scope 3", col3: "Low" },
  { id: 3, col1: "Waste", col2: "Scope 3", col3: "High" },
  { id: 4, col1: "Catering And Food", col2: "Scope 3", col3: "Moderate" },
  { id: 5, col1: "Catering And Food", col2: "Scope 3", col3: "Moderate" },
  { id: 6, col1: "Electricity", col2: "Scope 2", col3: "High" },
  { id: 7, col1: "Clearing & chemical", col2: "Scope 3", col3: "Low" },
  { id: 8, col1: "Catering And Food", col2: "Scope 3", col3: "Moderate" },
  { id: 9, col1: "Electricity", col2: "Scope 2", col3: "High" },
  { id: 10, col1: "Clearing & chemical", col2: "Scope 3", col3: "Low" },
  { id: 11, col1: "Grand total", col2: 5623, col3: 533397 },
];
const tableCellColor = (text) =>
  text === "Neutral"
    ? "#1E7723"
    : text === "Low"
    ? "#747120"
    : text === "High"
    ? "#D60000"
    : text === "Moderate"
    ? "#0472AE"
    : "#FFF";

const Dashboard = () => {
  const userDetails = useSelector((state) => state.auth.user);
  return (
    <Box className={styles.dashboardContainer}>
      <Typography variant="h5" className={styles.greet}>
        Hey there, {userDetails.email}!
      </Typography>
      <Typography variant="h6" className={styles.greetText} mb={3} mt={1}>
        Welcome back to your all in Dashboard and more text here!
      </Typography>
      <Stack direction="row" gap="48px" mb={3}>
        <Box sx={{ width: "60%" }}>
          <Box
            elevation={0}
            className={styles.leftSideContainer}
            sx={{
              boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.09)",
              borderRadius: "8px",
              background: "#FFF",
            }}
          >
            <Typography variant="h6" className={styles.tableTitle} p={2}>
              2022 Scope one, Two and Three Emissions
            </Typography>
            <Table className={styles.tableContainer} mb={3}>
              <TableHead className={styles.tableHead}>
                <TableRow className={styles.tableHeadRow}>
                  {table1Header.map((item) => (
                    <TableCell className={styles.tableHeadCell} key={item}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {table1Data.map((item) => (
                  <TableRow
                    className={`${styles.tableBodyRow} ${
                      item.headBodyRow ? styles.tableBodyHeaderRow : ""
                    }`}
                    key={item.id}
                  >
                    <TableCell className={styles.tableBodyCell}>
                      {item.col1}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.col2}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.col3}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Box
            sx={{
              boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.09)",
              borderRadius: "8px",
              background: "#FFF",
            }}
          >
            <Doughnut
              chartData={pieData}
              type="2022 Emissions Breakdown"
              chartHeight={300}
              bgColor="#fff"
              borderRadius={8}
            />
          </Box>
        </Box>
        <Paper
          gap="24px"
          sx={{ width: "40%", bgcolor: "#f6f8fc" }}
          elevation={0}
        >
          <Box
            sx={{
              boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.09)",
              borderRadius: "8px",
              background: "#FFF",
            }}
            mb={3}
          >
            <Doughnut
              chartData={pieData}
              type="Total Energy Consumption"
              chartHeight={300}
              bgColor="#fff"
              borderRadius={8}
            />
          </Box>
          <Paper
            gap="24px"
            sx={{
              boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.09)",
              borderRadius: "8px",
              background: "#FFF",
            }}
            elevation={0}
            className={styles.rightSideContainer}
          >
            <Typography variant="h6" className={styles.tableTitle} p={2}>
              Forecasting/tracking performance
            </Typography>
            <Table className={styles.tableContainer}>
              <TableHead className={styles.tableHead}>
                <TableRow className={styles.tableHeadRow}>
                  {table2Header.map((item) => (
                    <TableCell className={styles.tableHeadCell} key={item}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {table2Data.map((item) => (
                  <TableRow className={styles.tableBodyRow} key={item.id}>
                    <TableCell className={styles.tableBodyCell}>
                      {item.col1}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.col2}
                    </TableCell>
                    <TableCell
                      // className={styles.tableBodyCell}
                      sx={{ color: tableCellColor(item.col3) }}
                    >
                      {item.col3}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Paper>
      </Stack>
      <Paper
        mt={3}
        elevation={0}
        sx={{
          boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.09)",
        }}
      >
        <HorizontalBarChart bgColor="#fff" borderRadius={8} />
      </Paper>
    </Box>
  );
};

export default Dashboard;
