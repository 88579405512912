/** @format */

import React from "react";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import "../components/targets/targetList.css";

const ActionModal = ({ actions, open, setOpen }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "850px",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="wiewActionPlanModal">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="modal-title"
        >
          <Typography
            id="modal-modal-title"
            variant="p"
            component="h2"
            color="#0F333D"
          >
            Action Plans
          </Typography>
          <IconButton
            sx={{ marginRight: "24px" }}
            onClick={() => setOpen(false)}
          >
            <Close sx={{ color: "esgblueblack.main" }} />
          </IconButton>
        </Stack>
        <ul className="action-plan-list-items">
          {actions.map((action) => {
            return (
              <li className="action-plan-list-item" key={action.id}>
                <Typography
                  id="modal-modal-description"
                  sx={{ margin: "8px 0", color: "#577077" }}
                >
                  {action.action}
                </Typography>
              </li>
            );
          })}
        </ul>
      </Box>
    </Modal>
  );
};

export default ActionModal;
