/** @format */

import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import TabComponent from "../components/UI/TabComponent";
import ReportingAnalytics from "../components/reporting/ReportingAnalytics";
import RequestReport from "../components/reporting/RequestReport";
import DownloadTable from "../components/reporting/DownloadTable";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography variant="p">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Reporting = () => {
  const [value, setValue] = React.useState(1);
  // const [] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <TabComponent
        tab1Name={"Analytics"}
        tab1Width={"230px"}
        tab2Name={"Request report"}
        tab2Width={"230px"}
        value={value}
        handleChange={handleChange}
      />

      <CustomTabPanel value={value} index={0} className="target-list-tab-width">
        <ReportingAnalytics />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {true ? (
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #E8EBF2",
              mt: 3,
              p: 3,
            }}
          >
            <RequestReport />
          </Box>
        ) : (
          <DownloadTable />
        )}
      </CustomTabPanel>
    </Box>
  );
};

export default Reporting;
