/** @format */

import React, { useEffect, useState } from "react";
import styles from "./addScope1Details.module.css";
import { ArrowBack, Save } from "@mui/icons-material";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Skeleton,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ScopeRow from "../../components/scopes/ScopeRow";
import {
  requestConfig,
  addScope,
  updateScope,
  removeCategory,
} from "../../services/private/scope/ScopeService";
import { fethLocations } from "../../services/private/settings/SettingsService";
import { errorToast, promiseToast } from "../../utils/alerts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddScope1Details = ({ type, details, location_id, id }) => {
  const [selectedLocation, setSelectedLocation] = useState(location_id);
  const navigate = useNavigate();
  const [scopes, setScopes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [submit, setSubmit] = useState(false);

  // payload which we need to send with API
  const [rows, setRows] = useState([]);

  // ids for records details which we want to remove on save request
  const [ids, setIds] = useState([]);

  const serverRequest = async () => {
    if (type === "update") {
      await setLoading(true);
      await setScopes(details);
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const response = await requestConfig(1);
        setScopes(response.data);
        setLoading(false);
      } catch (error) {
        errorToast(error.message);
        setLoading(false);
      }
    }
  };

  const updateRows = async (records) => {
    setRows((prev) => {
      const newState = [...prev];
      records.forEach((record) => {
        const index = newState.findIndex(
          (row) => row.division_id === record.division_id
        );
        if (index !== -1) {
          newState[index] = record;
        } else {
          newState.push(record);
        }
      });
      return newState;
    });
  };

  const handleSave = async () => {
    setSubmit(true);
    let payload = {
      location_id: selectedLocation,
      scope: "1",
      details: rows,
    };

    // apply validations
    if (!selectedLocation) {
      errorToast("Location is required field");
      return;
    }
    if (ids.length > 0) {
      try {
        if (type === "update") {
          // check if that any ids exists in that row
          const finalIds = ids.filter(
            (id) => !rows.some((obj) => obj.division_id === id)
          );

          let payload = {
            id: id,
            ids: finalIds,
          };
          await removeCategory(payload);
        } else {
          // errorToast('Atleast one category value is required before adding');
          // return;
        }
      } catch (error) {
        errorToast(error.message);
      }
    }

    if (type === "update") {
      try {
        const promise = updateScope(id, payload);
        promiseToast(promise, "Updated Successfully!").then(() => {
          navigate("/scope1");
        });
      } catch (error) {
        errorToast(error.message);
      }
    } else {
      if (rows.length === 0) {
        errorToast("Atleast one category value is required before adding");
      } else {
        try {
          const promise = addScope(payload);
          promiseToast(promise, "Created Successfully!").then(() => {
            navigate("/scope1");
          });
        } catch (error) {
          errorToast(error.message);
        }
      }
    }
  };

  const requestLocations = async () => {
    try {
      const response = await fethLocations();
      setLocations(response.data);
    } catch (error) {
      errorToast(error.message);
    }
  };

  const removeRows = async (category_id) => {
    // filter rows
    const tempArr = rows.filter(
      (item) => parseInt(item.category_id) !== parseInt(category_id)
    );
    setRows(tempArr);

    if (!ids.includes(category_id)) {
      setIds((prev) => [...prev, category_id]);
    }
  };

  useEffect(() => {
    serverRequest();
    requestLocations();
    // eslint-disable-next-line
  }, []);

  return (
    <form name="scope3Data">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={styles.pageTitle}
      >
        <Stack
          direction="row"
          gap="16px"
          alignItems="center"
          className={styles.subNavigation}
        >
          <Box className={styles.backArrow}>
            <ArrowBack onClick={() => navigate("/scope1")} />
          </Box>
          <Stack direction="row" gap="16px" alignItems="center">
            <Typography className={styles.title}>
              Scope 1 Consumption
            </Typography>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99935 1.66663C5.39935 1.66663 1.66602 5.39996 1.66602 9.99996C1.66602 14.6 5.39935 18.3333 9.99935 18.3333C14.5993 18.3333 18.3327 14.6 18.3327 9.99996C18.3327 5.39996 14.5993 1.66663 9.99935 1.66663ZM10.8327 14.1666H9.16602V12.5H10.8327V14.1666ZM10.8327 10.8333H9.16602V5.83329H10.8327V10.8333Z"
                fill="#0F333D"
              />
            </svg>
          </Stack>
        </Stack>
        <Button
          startIcon={<Save />}
          variant="contained"
          onClick={() => handleSave()}
          sx={{ color: "#fff" }}
          className={styles.accordionSaveBtn}
          onChange={() => setSubmit(false)}
          error={submit && !selectedLocation}
        >
          Save
        </Button>
      </Stack>

      <Stack className={styles.scopeContainer}>
        <FormControl sx={{ fontFamily: "inherit" }}>
          <InputLabel id="demo-scope-label">Select Location</InputLabel>
          <Select
            labelId="demo-location-label"
            id="demo-location"
            value={selectedLocation || location_id}
            name="selectedAsset"
            input={<OutlinedInput label="Select Location" />}
            MenuProps={MenuProps}
          >
            {locations.map((location) => (
              <MenuItem
                key={location.id}
                value={location.id}
                id="input-dropdown"
                onClick={() => setSelectedLocation(location.id)}
              >
                {location.location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography className={styles.pageSubHeading}>
          Fill the following applicable emission values
        </Typography>
        {loading && (
          <div className="sk_dropdown">
            {Array(10)
              .fill(10)
              .map((skel, index) => (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  style={{ marginTop: 15, background: "#f6f7fb" }}
                  height={50}
                  key={index}
                />
              ))}
          </div>
        )}

        {scopes.map((item) => (
          <>
            <ScopeRow
              scope={item}
              updateRows={updateRows}
              removeRows={removeRows}
              setSubmit={setSubmit}
            />
          </>
        ))}
      </Stack>
    </form>
  );
};

export default AddScope1Details;
