/** @format */

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./addEnergyDetails.module.css";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  fethLocations,
  fethLocationsValues,
} from "../../services/private/config/config";
import moment from "moment";
import { generateArray } from "../../utils/UniversalFunction";
import {
  addEnergyValues,
  updateEnergyValues,
} from "../../services/private/energy/EnergyService";
import { errorToast, promiseToast } from "../../utils/alerts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddEnergyDetails = ({ details, type }) => {
  const [energyData, setEnergyData] = useState({
    id: details?.id,
    selectedAsset: details?.location_id,
    selectedBms: details?.bms,
    selectedUnit: details?.units,
    greenPower: details?.green_power,
  });
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [entity, setEntity] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  // eslint-disable-next-line

  const [values, setValues] = useState(details?.records || []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setEnergyData({ ...energyData, [name]: value });
  };

  const requestLocations = async () => {
    try {
      const entity = await fethLocations();
      setLocations(entity.data);
    } catch (error) {
      errorToast(error.message);
    }
  };

  const requestMonths = async () => {
    try {
      if (entity) {
        const response = await fethLocationsValues(entity);
        setValues(response.data.entities);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const handelEntityChange = async (e) => {
    const { name, value } = e.target;
    const entity = name.split("-");
    const month = entity[0];
    const year = entity[1];
    const index = values.findIndex(
      (item) => item.months === month && item.years === year
    );
    let tempValues = [...values];
    tempValues[index].values = value;
    setValues(tempValues);
  };

  const handelSave = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    let isValid = true;
    values.forEach((item) => {
      if (
        item.values === "" ||
        item.values === null ||
        item.values === undefined
      ) {
        isValid = false;
      }
    });
    if (!isValid) {
      errorToast("All fields are required!");
    } else {
      if (
        !energyData.selectedBms ||
        !energyData.selectedUnit ||
        !energyData.greenPower ||
        !energyData.selectedAsset
      ) {
        errorToast("All fields are required!");
      } else {
        let formattedArray = await generateArray(values);

        let payload = {
          location_id: `${energyData.selectedAsset}`,
          bms: energyData.selectedBms,
          units: energyData.selectedUnit,
          green_power: energyData.greenPower,
          years: formattedArray.years,
          months: formattedArray.months,
          values: formattedArray.values,
        };

        if (type === "update") {
          const apiResponse = updateEnergyValues(energyData?.id, payload);

          promiseToast(apiResponse, "Updated Successfully!");
          apiResponse
            .then(() => {
              navigate("/energy");
            })
            .catch((error) => {
              // console.log("ERROR", error);
            });
        } else {
          const apiResponse = addEnergyValues(payload);
          promiseToast(apiResponse, "Created Successfully");
          apiResponse
            .then(() => {
              navigate("/energy");
            })
            .catch((error) => {
              // console.log("ERROR", error);
            });
        }
      }
    }
  };

  useEffect(() => {
    requestMonths();
    // eslint-disable-next-line
  }, [entity]);

  useEffect(() => {
    requestLocations();
    // eslint-disable-next-line
  }, []);

  return (
    <form name="energyData">
      <Stack direction="row" gap="16px" alignItems="center">
        <Box className={styles.backArrow}>
          <ArrowBack onClick={() => navigate("/energy")} />
        </Box>
        <Typography className={styles.title}>Fill the details</Typography>
      </Stack>
      <Stack className={styles.assetFormContainer}>
        <FormControl sx={{ fontFamily: "inherit" }}>
          <InputLabel id="demo-scope-label">Select Asset</InputLabel>
          <Select
            labelId="demo-scope-label"
            id="demo-scope"
            value={energyData.selectedAsset || ""}
            name="selectedAsset"
            defaultValue={""}
            onChange={(e) => handleChange(e)}
            input={<OutlinedInput label="Select Asset" />}
            MenuProps={MenuProps}
            error={submitted && !energyData.selectedAsset}
          >
            {locations.map((location) => (
              <MenuItem
                key={location?.id}
                value={location?.id}
                id="input-dropdown"
                onClick={() => setEntity(location?.id)}
              >
                {location?.location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" gap="24px" mt={4}>
          <TextField
            label="Linked to BMS"
            sx={{ width: "100%" }}
            name="selectedBms"
            value={energyData.selectedBms || ""}
            onChange={handleChange}
            required={true}
            error={submitted && !energyData.selectedBms}
            helperText={
              submitted && !energyData.selectedBms ? "Required field" : ""
            }
          />
          <TextField
            label="Unit"
            sx={{ width: "100%" }}
            name="selectedUnit"
            value={energyData.selectedUnit || ""}
            onChange={handleChange}
            required={true}
            error={submitted && !energyData.selectedUnit}
            helperText={
              submitted && !energyData.selectedUnit ? "Required field" : ""
            }
          />
          <TextField
            label="Green Power %"
            sx={{ width: "100%" }}
            name="greenPower"
            type="number"
            value={energyData.greenPower || ""}
            onChange={handleChange}
            required={true}
            error={submitted && !energyData.greenPower}
            helperText={
              submitted && !energyData.greenPower ? "Required field" : ""
            }
          />
        </Stack>

        <Typography
          className={styles.enterMonthly}
          variant="span"
          mb={2}
          mt={4}
        >
          Enter your monthly value
        </Typography>
        {energyData.selectedAsset && (
          <Box className={styles.gridContainer} mb={4}>
            {values.map((item, index) => (
              <TextField
                label={
                  moment(item.months, "MMMM").format("MMM") + "-" + item.years
                }
                sx={{ width: "100%" }}
                name={`${item.months}-${item.years}`}
                type="number"
                value={item.values}
                onChange={(e) => handelEntityChange(e, index)}
                key={index}
              />
            ))}
          </Box>
        )}
        <Stack direction="row" justifyContent="flex-end" gap="16px">
          <Button variant="outlined" onClick={() => navigate("/energy")}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={(e) => handelSave(e)}
          >
            {type === "update" ? "Update" : "Save"}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default AddEnergyDetails;
