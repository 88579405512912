/** @format */

import React from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import SideBar from "./SideBar";
import Header from "./Header";
import styles from "./layout.module.css";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <Box className="app-layout">
      <SideBar />
      <Stack className={styles.layoutRight}>
        <Header />
        <Box
          sx={{
            width: "100%",
            padding: "24px",
            marginTop: "64px",
            bgcolor: "#F6F8FC",
            paddingBottom: 0,
          }}
        >
          {location.pathname.includes("data-vault") && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={styles.instruction}>
                Ensure your Data Vault is up to date with all changes.
              </Typography>
              <Button variant="contained" sx={{ color: "#fff" }}>
                share data vault
              </Button>
            </Stack>
          )}
          <Paper
            sx={{
              bgcolor: "#FFF",
              padding:
                location.pathname === "/" ||
                location.pathname.includes("data-vault") ||
                location.pathname.includes("asset-management") ||
                location.pathname.includes("view-assets")
                  ? "0"
                  : "24px",
            }}
            elevation={0}
          >
            {children}
          </Paper>
        </Box>
      </Stack>
    </Box>
  );
};

export default Layout;
