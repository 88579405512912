  const headers = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const headers = {
        'Authorization': `Bearer ${user.token}`,
        "content-type": "application/json",
    };
    return headers
  }

  module.exports = {
    headers
  }