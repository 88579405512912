/** @format */

import React, { useState } from "react";
import styles from "./esgiChart.module.css";
import { Button } from "@mui/material";
import { ModeEditOutline } from "@mui/icons-material";
import ReactApexChart from "react-apexcharts";

const EsgiChart = () => {
  // eslint-disable-next-line
  const [rating, setRating] = useState(64.6);
  const chartData = {
    series: [rating],
    options: {
      chart: {
        type: "radialBar",
      },
      colors: ["#33AE5E"],
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: {
            background: "#fff",
            startAngle: -135,
            endAngle: 135,
          },
          dataLabels: {
            name: {
              show: false,
              color: "#0F333D",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            },
            value: {
              fontSize: "20px",
              show: false,
            },
          },
          hollow: {
            margin: 0,
            size: "80%", // Adjust the size of the hollow center
            background: "#fff",
            image: undefined,
            position: "front",
          },
        },
      },
      fill: {
        type: "solid",
      },
      stroke: {
        lineCap: "butt",
      },
    },
  };
  const chartData2 = {
    series: [100],
    options: {
      chart: {
        type: "radialBar",
      },
      colors: ["#E7F5EC"],
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: {
            background: "#fff",
            startAngle: -135,
            endAngle: 135,
          },
          dataLabels: {
            name: {
              show: true,
              color: "#0F333D",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            },
            value: {
              show: false,
            },
          },
          hollow: {
            margin: 0,
            size: "50",
            background: "#fff",
            image: undefined,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 10,
              opacity: 0.1,
            },
          },
        },
        hover: null,
      },
      fill: {
        type: "solid",
      },
      stroke: {
        lineCap: "butt",
      },
    },
  };
  const chartData3 = {
    series: [100],
    options: {
      chart: {
        type: "radialBar",
      },
      colors: ["#E7F5EC"],
      plotOptions: {
        radialBar: {
          startAngle: -180,
          endAngle: 180,
          track: {
            background: "#E7F5EC",
            startAngle: -180,
            endAngle: 180,
          },
          dataLabels: {
            name: {
              show: true,
              color: "#0F333D",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            },
            value: {
              show: false,
            },
          },
          hollow: {
            margin: 0,
            size: "80",
            background: "#fff",
            image: undefined,
            position: "front",
          },
        },
        hover: null,
      },
      fill: {
        type: "solid",
      },
      stroke: {
        lineCap: "butt",
      },
      labels: [`${rating * 10}/1000`],
    },
  };

  return (
    <div className={styles.chartContainer}>
      <Button
        startIcon={<ModeEditOutline color="primary" />}
        sx={{ marginLeft: "8px" }}
        className={styles.btn}
      >
        Edit
      </Button>
      <div className={styles.chart1}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="radialBar"
          height={350}
        />
        <div className={styles.chart2}>
          <ReactApexChart
            options={chartData2.options}
            series={chartData2.series}
            type="radialBar"
            height={400}
          />
        </div>
        <div className={styles.bottomShadow}></div>
        <div className={styles.chart3}>
          <ReactApexChart
            options={chartData3.options}
            series={chartData3.series}
            type="radialBar"
            height={200}
          />
        </div>
      </div>
    </div>
  );
};
export default EsgiChart;
