import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddWasteDetails from "./AddWasteDetails";
import { requestWasteConsumptionDetails } from "../../services/private/waste/WasteService";
import { errorToast } from "../../utils/alerts";
const UpdateWasteDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const requestDetails = async () => {
    try {
      setLoading(true);
      const response = await requestWasteConsumptionDetails(id);
      await setDetails(response.data);
      setLoading(false);
    } catch (error) {
      errorToast(error.message);
    }
  };

  useEffect(() => {
    requestDetails();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div>
      {!loading && <AddWasteDetails details={details} type={"update"} />}
    </div>
  );
};

export default UpdateWasteDetails;
