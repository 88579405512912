/** @format */

import { Add, Remove } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./tcfdAccordion.module.css";

const TcfdAccordion = ({ data }) => {
  const initialExpandedState = data.reduce((acc, accordion) => {
    acc[accordion.id] = false;
    return acc;
  }, {});
  const [expandedAccordions, setExpandedAccordions] =
    useState(initialExpandedState);

  const handleToggle = (accordionId) => {
    setExpandedAccordions((prevExpanded) => ({
      ...prevExpanded,
      [accordionId]: !prevExpanded[accordionId],
    }));
  };
  return (
    <Stack className={styles.accordionContainer} gap={2} mt={2} pb={3}>
      {data.map((item) => (
        <Accordion
          expanded={expandedAccordions[item.id]}
          onChange={() => handleToggle(item.id)}
          elevation={0}
          key={item.id}
          sx={{
            borderRadius: "8px",
            background: "#F6F7FB",
            padding: "12px 8px",
          }}
        >
          <AccordionSummary
            expandIcon={
              <IconButton>
                {expandedAccordions[item.id] ? <Remove /> : <Add />}
              </IconButton>
            }
            aria-controls={`panel${item.id}-content`}
            id={`panel${item.id}-header`}
          >
            <Typography className={styles.summary}>
              {item.accordionSummary}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={styles.details}>
              {item.accordionDetails}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};

export default TcfdAccordion;
