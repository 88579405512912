import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchEnergyDetails } from '../../services/private/energy/EnergyService';
import AddEnergyDetails from './AddEnergyDetails';
import {errorToast} from '../../utils/alerts';
const UpdateEnergyDetails = () => {

    const { id } =  useParams();
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const requestDetails = async() => {
        try{
            setLoading(true);
            const response = await fetchEnergyDetails(id);
            await setDetails(response.data);
            setLoading(false);
        }catch(error){
            errorToast(error.message);
        }
    }

    useEffect(() => {
        requestDetails()
        // eslint-disable-next-line
    }, [id]);

  return (
    <div>
        {!loading && (
            <AddEnergyDetails details={details} type={'update'}/>
        )}
    </div>
  );
}

export default UpdateEnergyDetails