export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== "string") {
      content = JSON.stringify(content);
    }
    return window.localStorage.setItem(name, content);
  };
  
  /**
   * Get localStorage
   */
  export const getStore = (name) => {
    if (!name) return;
    return JSON.parse(window.localStorage.getItem(name));
  };

  export const generateUniqueID = () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000000); // You can adjust the range as needed
    return `${timestamp}_${random}`;
  }

  export const generateArray = async (array) => {

    const years = await array.map((item) => {
      return `${item.years}`;
    });

    const months = await array.map((item) => {
      return `${item.months}`;
    });

    const values = await array.map((item) => {
      return parseInt(item.values);
    });

    return { years, months, values }

  }