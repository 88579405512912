/** @format */

import React from "react";
import IntegrationItem from "../UI/IntegrationItem";
import { Box } from "@mui/material";
import "./integrationTab.css";

const IntegrationTab = () => {
  // const [checked, setChecked] = React.useState(true);
  const cardContent = [
    {
      title: "Software for data #1",
      content:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet maximeminima praesentium quibusdam magni illum iure, tempora id ea quidem oditmolestiae? Saepe cum officiis sapiente amet possimus corporis deserunt!",
    },
    {
      title: "Software for data #2",
      content:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet maximeminima praesentium quibusdam magni illum iure, tempora id",
    },
  ];
  return (
    <Box className="integration-card">
      {cardContent.map((item) => (
        <IntegrationItem
          title={item.title}
          content={item.content}
          // checked={checked}
          // setChecked={setChecked}
        />
      ))}
    </Box>
  );
};

export default IntegrationTab;
