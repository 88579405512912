/** @format */

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { stackedBarData } from "../../Data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  aspectRatio: 1,
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false, // Remove vertical grid lines
      },
      ticks: {
        min: 0,
        max: 7,
        stepSize: 1,
      },
    },
    y: {
      stacked: true,
      ticks: {
        min: 0,
        max: 6000,
        stepSize: 1000,
        callback: function (value) {
          return value >= 1000 ? `${value / 1000}k` : value;
        },
      },
      title: {
        display: false,
      },
      display: true,
    },
  },
};

export const data = {
  labels: stackedBarData.map((item) => item.day),
  datasets: [
    {
      label: "Option 1",
      data: stackedBarData.map((item) => item.option1target),
      backgroundColor: "#E7F5EC",
      barThickness: 10,
      // barPercentage: 0.2, // Adjust this value to control the width of the bars
      // categoryPercentage: 1, // Set to 1 to fill the entire space for each category
      // barThickness: "flex", // Use 'flex' to distribute the bars evenly across the available space
    },
    {
      label: "Option 2",
      data: stackedBarData.map((item) => item.option2target),
      backgroundColor: "#33AE5E",
      barThickness: 10,
    },
  ],
};
const StackedBarChart = () => {
  return <Bar options={options} data={data} />;
};

export default StackedBarChart;
