/** @format */

import { Stack } from "@mui/material";
import React from "react";
import BarChart from "../charts/BarChart";
import { UserData } from "../../Data";
import HorizontalBarChart from "../charts/HorizontalBarChart";

const TargetsAnalytics = () => {
  const userData = {
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "User Gain",
        type: "line",
        data: UserData.map((data) => data.userGain),
        borderColor: "#6DB3B5",
        fill: true,
        tension: 0,
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      {
        label: false,
        type: "bar",
        data: UserData.map((data) => data.userGain),
        backgroundColor: "#165BAA",
        barThickness: 32,
      },
    ],
  };

  return (
    <Stack gap="24px">
      <HorizontalBarChart />
      <BarChart
        chartData={userData}
        yAxisTitle="C0-2-Emissions [tC02e]"
        chartTitle="Emissions Reduction Targets.(C02E)"
        aspectRatio={5}
      />
    </Stack>
  );
};

export default TargetsAnalytics;
