/** @format */

import { Stack } from "@mui/material";
import React from "react";
import BarChart from "../components/charts/BarChart";
import { PieData, UserData } from "../Data";
import DoughnutChart from "../components/charts/Doughnut";

const WasteAnalytics = () => {
  const userData = {
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "User Gain",
        type: "line",
        data: UserData.map((data) => data.userGain),
        borderColor: "#6DB3B5",
        fill: true,
        tension: 0,
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      {
        label: false,
        type: "bar",
        data: UserData.map((data) => data.userGain),
        backgroundColor: "#165BAA",
        barThickness: 32,
      },
    ],
  };
  const pieData = {
    labels: PieData.map((data) => data.year),
    datasets: [
      {
        label: "User Lost",
        type: "doughnut",
        data: PieData.map((data) => data.userLost),
        backgroundColor: ["#A155B9", "#165BAA", "#F765A3", "#16BFD6"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      <Stack gap="24px">
        <BarChart
          chartData={userData}
          yAxisTitle="Waste Consumption [m3]"
          chartTitle="Yearly Waste Consumption"
          aspectRatio={5}
        />
      </Stack>
      <Stack direction="row" gap="24px">
        <DoughnutChart
          chartData={pieData}
          type="Total Waste Consumption"
          chartHeight={300}
        />
        <DoughnutChart
          chartData={pieData}
          type="Total Waste Consumption"
          chartHeight={300}
        />
      </Stack>
    </>
  );
};

export default WasteAnalytics;
