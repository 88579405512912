/** @format */

import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./modal.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  height: "333px",
  borderRadius: "8px",
  background: "#FFF",
};
const GenerateCustomReportModal = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.modalContainer}
    >
      <Box sx={style}>
        <Stack
          direction="row"
          justifyContent="space-between"
          className={styles.modalTitleCntainer}
        >
          <Typography className={styles.modalTitle}>
            Generate Custom Report
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector"
                d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                fill="#82959C"
              />
            </svg>
          </IconButton>
        </Stack>
        <Box className={styles.modalDescription}>
          <Typography variant="span" className={styles.modaltext}>
            Thank you for choosing our custom report service. Your report will
            be generated in 24 hours.
          </Typography>
          <ul className={styles.listContainer}>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Carbon footprint
            </li>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Emissions reduction targets
            </li>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Carbon pricing
            </li>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Renewable energy usuage
            </li>
          </ul>
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="contained"
              className={styles.confirmBtn}
              sx={{ color: "#fff" }}
            >
              Confirm and Generate Report
            </Button>
            <Button
              variant="outlined"
              className={styles.cancelBtn}
              onClick={() => setOpen(false)}
            >
              CANCEL
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default GenerateCustomReportModal;
