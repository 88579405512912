/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    id: null,
    email: null,
    role: null,
    token: null,
    firstName: null,
    lastName: null,
    logo: null
  }
};

export const authSlice = createSlice({
  name: "target",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = {
        id: action.payload.id,
        email: action.payload.email,
        role: action.payload.role,
        token: action.payload.token,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        logo: action.payload.logo
      };
    },
    logout: (state) => {
        state.user = {
          id: null,
          email: null,
          role: null,
          token: null,
          firstName: null,
          lastName: null,
          logo: null
        };
      }
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
