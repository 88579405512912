/** @format */

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./accordionQuestionnaire.module.css";
import { getDataVault } from "../../../services/private/dataVault/dataVaultService";
import { errorToast } from "../../../utils/alerts";

const AccordionQuestionnaire = ({ tab, type }) => {
  const [selected1Option, setSelected1Option] = useState("");
  const [selected2Option, setSelected2Option] = useState("");
  const [selected3Option, setSelected3Option] = useState("");
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const handleOption1Change = (event) => {
    setSelected1Option(event.target.value);
  };
  const handleOption2Change = (event) => {
    setSelected2Option(event.target.value);
  };
  const handleOption3Change = (event) => {
    setSelected3Option(event.target.value);
  };
  const serverRequest = async () => {
    try {
      setIsLoading(true);
      const rep = await getDataVault(tab, type);
      setData(rep);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    } catch (error) {
      errorToast(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    serverRequest();
    // eslint-disable-next-line
  }, []);
  return (
    <Stack className={styles.accordionContainer}>
      <FormControl>
        <Stack gap={3}>
          <Stack
            sx={{
              borderRadius: "8px",
              background: "#fff",
              boxShadow: "0px 4px 24px 0px rgba(15, 51, 61, 0.10)",
            }}
            p={3}
          >
            <Typography variant="p" className={styles.questionLabel}>
              Does the entity have one or more persons responsible for
              implementing ESG objectives?
            </Typography>
            <RadioGroup
              aria-label="YesNoOptions"
              name="yesNoOptions"
              value={selected1Option}
              onChange={handleOption1Change}
              sx={{ display: "flex", flexDirection: "row", gap: "16px" }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>

            {selected1Option === "yes" && (
              <Stack>
                <Typography variant="p" className={styles.questionLabel}>
                  Dedicated employee(s) for whom ESG is the core responsibility.
                  Provide the details for the most senior of these employees .
                </Typography>
                <Stack direction="row" gap={2}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Typography
                  variant="p"
                  className={styles.questionLabel}
                  mt={2}
                  mb={2}
                >
                  Employee(s) for whom ESG is among their responsibilities.
                  Provide the details for the most senior of these employees
                </Typography>
                <Stack direction="row" gap={2}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Typography
                  variant="p"
                  className={styles.questionLabel}
                  mt={2}
                  mb={2}
                >
                  External consultants/manager. Provide the details of the main
                  contact
                </Typography>
                <Stack direction="row" gap={2}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Typography
                  variant="p"
                  className={styles.questionLabel}
                  mt={2}
                  mb={2}
                >
                  External consultants/manager. Provide the details of the main
                  contact
                </Typography>
                <Stack direction="row" gap={2}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack
            sx={{
              borderRadius: "8px",
              background: "#fff",
              boxShadow: "0px 4px 24px 0px rgba(15, 51, 61, 0.10)",
            }}
            p={3}
          >
            <Typography variant="p" className={styles.questionLabel}>
              Does the entity have one or more persons responsible for
              implementing climate-related objectives?
            </Typography>
            <RadioGroup
              aria-label="YesNoOptions"
              name="yesNoOptions"
              value={selected2Option}
              onChange={handleOption2Change}
              sx={{ display: "flex", flexDirection: "row", gap: "16px" }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>

            {selected2Option === "yes" && (
              <Stack>
                <Stack direction="row" gap={2} sx={{ width: "100%" }}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Typography
                  variant="p"
                  className={styles.questionLabel}
                  mt={2}
                  mb={2}
                >
                  Employee(s) for whom ESG is among their responsibilities.
                  Provide the details for the most senior of these employees
                </Typography>
                <Stack direction="row" gap={2} sx={{ width: "100%" }}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Typography
                  variant="p"
                  className={styles.questionLabel}
                  mt={2}
                  mb={2}
                >
                  External consultants/manager. Provide the details of the main
                  contact
                </Typography>
                <Stack direction="row" gap={2} sx={{ width: "100%" }}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Typography
                  variant="p"
                  className={styles.questionLabel}
                  mt={2}
                  mb={2}
                >
                  Investment partners (co-investors/JV partners). Provide the
                  details of the main contact
                </Typography>
                <Stack direction="row" gap={2} sx={{ width: "100%" }}>
                  <TextField
                    variant="standard"
                    label="Name"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    variant="standard"
                    label="Job Title"
                    sx={{ width: "100%" }}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack
            sx={{
              borderRadius: "8px",
              background: "#fff",
              boxShadow: "0px 4px 24px 0px rgba(15, 51, 61, 0.10)",
            }}
            p={3}
          >
            <Typography variant="p" className={styles.questionLabel}>
              Is there an ESG taskforce or committee?
            </Typography>
            <RadioGroup
              aria-label="YesNoOptions"
              name="yesNoOptions"
              value={selected3Option}
              onChange={handleOption3Change}
              sx={{ display: "flex", flexDirection: "row", gap: "16px" }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>

            {selected3Option === "yes" && (
              <>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Board of Directors
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    C-suite level staff/Senior management
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Investment Committee
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Fund/portfolio managers
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Asset managers
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    ESG portfolio manager
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Investment analysts
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Dedicated staff on ESG issues
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    External managers or service providers
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Investor relations
                  </Typography>
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Checkbox />
                  <Typography variant="p" className={styles.questionLabel}>
                    Other:
                  </Typography>
                  <TextField variant="standard" />
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </FormControl>

      <Stack direction="row" mt={2} gap={2}>
        <Button variant="contained" sx={{ color: "#fff" }}>
          Save
        </Button>
        <Button>Clear</Button>
      </Stack>
    </Stack>
  );
};

export default AccordionQuestionnaire;
