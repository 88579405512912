import React, { useState, useEffect } from "react";
import { requestScopeValue } from "../../services/private/scope/ScopeService";
import { useParams } from "react-router-dom";
import AddScope3Details from "./AddScope3Details";
import { Backdrop, CircularProgress } from "@mui/material";
import { errorToast } from '../../utils/alerts';

const UpdateScope3 = () => {
  const [details, setDetails] = useState([]);
  const [loc, setLoc] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const serverRequest = async () => {
    try {
      setLoading(true);
      const response = await requestScopeValue(id);
      await setDetails(response.data);
      await setLoc(response.location_id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error.message)
    }
  };

  useEffect(() => {
    serverRequest();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <AddScope3Details
          type={"update"}
          details={details}
          location_id={loc}
          id={id}
        />
      )}
    </>
  );
};

export default UpdateScope3;
