/** @format */

import { Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./energyItemCard.module.css";
import moment from "moment";

const EnergyItemCard = ({ month, year, value }) => {
  return (
    <Stack
      direction="row"
      gap="4px"
      alignItems="center"
      padding="4px"
      className={styles.monthCard}
      bgcolor="#fff"
      justifyContent="space-between"
    >
      <Typography
        color="primary"
        bgcolor="#E8EBF2"
        padding="4px 12px"
        borderRadius="4px"
      >
        {moment(month, "MMM").format("MMM").toUpperCase()}-
        <strong>{year}</strong>
      </Typography>
      <Typography className={styles.cardValue}>{value}</Typography>
    </Stack>
  );
};

export default EnergyItemCard;
