/** @format */

import { Button, Stack, Tab, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./assetManagement.module.css";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ScrollableTable from "./ScrollableTable";
import {
  assetResilience,
  energyEfficiencyMeasures,
  wasteManagementMeasures,
  waterEfficiencyMeasures,
} from "../../constant/assetManagement";
import EsgTargetsUnderPlan from "./EsgTargetsUnderPlan";

const AssetManagementPlan = () => {
  const [value, setValue] = useState("1");
  const handleInnerTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack p={3} className={styles.assetContainer}>
      <Typography variant="h5" className={styles.pageTitle} mb={2}>
        Asset Management Plan
      </Typography>
      <Typography variant="p" className={styles.details}>
        A comprehensive understanding the property you own or rent is important
        for your organisations future reporting obligations. The below criteria
        is a comprehensive list of information you should understand about each
        property you own or occupy. An effective asset management plan will
        utilise this data to understand what property or technology upgrades
        should be completed over the next 10 years to ensure you are able to
        meet your energy, waste, water and GHG emission targets.
      </Typography>
      <TabContext value={value}>
        <TabList onChange={handleInnerTab} sx={{ padding: "24px 0" }}>
          <Tab label="1.	Asset Resilience" value="1"></Tab>
          <Tab label="2.	energy efficiency measures" value="2" />
          <Tab label="3.	water efficiency measures" value="3" />
          <Tab label="4.	waste management measures" value="4" />
          <Tab label="5.	esg targets" value="5" />
        </TabList>
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Button
            variant="contained"
            sx={{
              color: "white",
              visibility: `${value === "5" ? "hidden" : ""}`,
            }}
            size="small"
          >
            add new asset
          </Button>
          <TextField
            variant="outlined"
            label="Search"
            placeholder="Name, email, etc..."
            size="small"
          />
        </Stack>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={assetResilience}
            options={["LOW", "MEDIUM", "HIGH"]}
            colLength={19}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={energyEfficiencyMeasures}
            options={["True", "False", "N/A"]}
            colLength={8}
          />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={waterEfficiencyMeasures}
            options={["True", "False", "N/A"]}
            colLength={11}
          />
        </TabPanel>
        <TabPanel value="4" sx={{ padding: 0 }}>
          <ScrollableTable
            tableContent={wasteManagementMeasures}
            options={["True", "False", "N/A"]}
            colLength={6}
          />
        </TabPanel>
        <TabPanel value="5" sx={{ padding: 0 }}>
          <EsgTargetsUnderPlan />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default AssetManagementPlan;
