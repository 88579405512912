/** @format */

import React, { useState } from "react";
import DataVault from "../DataVault";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./sdgQuestionnaire.module.css";
import { Upload } from "@mui/icons-material";

const sdgList = [
  {
    sdgTitle:
      "SDG 1 - End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
    sdgbrief:
      "This will be a brief explanation. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularized in the 1960s with the release of Letterset sheets containing Lorem Ipsum passages,",
  },
];
const sdgQuestions = [
  {
    id: 1,
    question:
      "By 2030, end hunger and ensure access by all people, in particular the poor and people in vulnerable situations, including infants, to safe, nutritious and sufficient food all year round",
    response: null,
  },
  {
    id: 2,
    question:
      "By 2030, double the agricultural productivity and incomes of small-scale food producers, in particular women, indigenous peoples, family farmers, pastoralists and fis.....",
    response: null,
  },
  {
    id: 3,
    question:
      "By 2030, ensure sustainable food production systems and implement resilient agricultural practices that increase productivity and production, that help maintain ecos....",
    response: null,
  },
  {
    id: 4,
    question:
      "By 2020, maintain the genetic diversity of seeds, cultivated plants and farmed and domesticated animals and their related wild species, including through soundly mana....",
    response: null,
  },
  {
    id: 5,
    question:
      "Increase investment, including through enhanced international cooperation, in rural infrastructure, agricultural research and extension services, technology development ...",
    response: null,
  },
  {
    id: 6,
    question:
      "Correct and prevent trade restrictions and distortions in world agricultural markets, including through the parallel elimination of all forms of agricultural export subsidies and ...",
    response: null,
  },
  {
    id: 7,
    question:
      "Adopt measures to ensure the proper functioning of food commodity markets and their derivatives and facilitate timely access to market information, including on food rese ...",
    response: null,
  },
];
const SdgQuestionnaire = () => {
  const [questionResponses, setQuestionResponses] = useState(sdgQuestions);

  const handleResponseChange = (value, questionId) => {
    console.log(value, questionId);
    const updatedResponses = questionResponses.map((question) => {
      if (question.id === questionId) {
        return { ...question, response: value };
      }
      return question; // Return the original question if it doesn't match the ID
    });
    setQuestionResponses(updatedResponses);
  };

  return (
    <DataVault>
      {sdgList.map((item) => (
        <Stack
          key={item.id}
          className={styles.sdgQuestionnaireContainer}
          p={3}
          gap={2}
        >
          <Typography variant="h5" className={styles.title}>
            {item.sdgTitle}
          </Typography>
          <Typography variant="p" className={styles.brief}>
            {item.sdgbrief}
          </Typography>
          <Typography variant="p" className={styles.questionCount}>
            Questions Answered: 0/23
          </Typography>
          <Stack gap={2}>
            {questionResponses.map((question) => (
              <Stack
                sx={{
                  borderRadius: "8px",
                  background: "#fff",
                  boxShadow: "0px 4px 24px 0px rgba(15, 51, 61, 0.10)",
                }}
                p={3}
                key={question?.id}
              >
                <Typography variant="p" className={styles.questionLabel}>
                  {question?.question}
                </Typography>
                <RadioGroup
                  aria-label="YesNoOptions"
                  value={question?.response || ""}
                  onChange={(e) =>
                    handleResponseChange(e.target.value, question?.id)
                  }
                  sx={{ display: "flex", flexDirection: "row", gap: "16px" }}
                >
                  {["yes", "no"].map((option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={
                        option === "yes"
                          ? "Yes, I support this goal."
                          : "No, this is not relevant to me."
                      }
                      checked={question?.response === option}
                    />
                  ))}
                </RadioGroup>

                {question?.response === "yes" && (
                  <Stack gap={2} mt={2}>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      gap={2}
                      className={styles.commentContainer}
                    >
                      <TextField
                        label="Comments"
                        sx={{ width: "100%" }}
                        multiline
                        minRows={2}
                        maxRows={4}
                        InputLabelProps={{
                          placeholder:
                            "Add your comments, attach supporting documents",
                          style: {
                            color: "#4F5367",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          },
                        }}
                      />

                      <Button
                        startIcon={<Upload color="primary" />}
                        sx={{ marginLeft: "8px" }}
                      >
                        <Typography>Upload File</Typography>
                      </Button>
                    </Stack>
                    <Stack direction="row" gap={2}>
                      <Button variant="contained" sx={{ color: "#fff" }}>
                        Save
                      </Button>
                      <Button variant="outlined">Clear</Button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </DataVault>
  );
};

export default SdgQuestionnaire;
