/** @format */

import React, { useState } from "react";
import EnergyItemCard from "../energy/EnergyItemCard";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import styles from "./monthRecordcard.module.css";
import { useNavigate } from "react-router-dom";
const MonthRecordCard = ({
  entity,
  deleteRow,
  handelUpdate,
  label,
  addRoute,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handelDelete = async () => {
    handleClose();
    await deleteRow(entity?.id);
  };

  return (
    <Stack
      gap="16px"
      sx={{
        borderRadius: "8px",
        background: "#F6F7FB",
        margin: "16px 0",
        padding: "16px",
      }}
    >
      <div className={styles.energyItemHeadline}>
        <p>{entity?.locations}</p>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handelUpdate(entity?.id)}>Update</MenuItem>
          <MenuItem onClick={handelDelete}>Delete</MenuItem>
          {addRoute && (
            <MenuItem onClick={() => navigate(addRoute)}>View</MenuItem>
          )}
        </Menu>
      </div>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="p" className={styles.energyItemSubline}>
          {label}
        </Typography>
        <Stack direction="row" gap="8px">
          <Typography className={styles.energyAmount}>Total:</Typography>
          <Typography className={styles.energyAmount}>
            {entity?.total}
          </Typography>
        </Stack>
      </Stack>

      <Box className={styles.container}>
        {entity.records.map((item, index) => (
          <EnergyItemCard
            month={item.months}
            year={item.years}
            value={item.values}
            key={index}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default MonthRecordCard;
