import axios from "axios";
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';

const PREFIX = 'carbon/config/';


export const fethCategories = async (scope) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}getCategories/${scope}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const fethDivisions = async (category) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}getDivisions/${category}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const fethSubUsers = async () => {

    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}getSubUsers`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }

};

export const fethLocations = async () => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}getLocations`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const scopesList = async () => {
    return [
        { label: 'Energy consumption',value: '0' },
        { label: 'Scope 1', value: '1' },
        { label: 'Scope 2', value: '2' },
        { label: 'Scope 3', value: '3' },
        { label: 'Water', value: '4' },
        { label: 'Waste', value: '5' }
    ]
};

export const fethLocationsValues = async (id) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}getMonthsList/${id}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};