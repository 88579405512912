/** @format */

import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  FormControl,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  OutlinedInput,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./addWaterDetails.module.css";
import theme from "../../components/theme";
import {
  fethLocations,
  fethLocationsValues,
} from "../../services/private/config/config";
import moment from "moment";
import {
  saveWaterConsumption,
  saveWaterConsumptionDetail,
  updateWaterConsumption,
} from "../../services/private/water/WaterService";
import { generateArray } from "../../utils/UniversalFunction";
import { successToast, errorToast } from "../../utils/alerts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddWaterDetails = ({ details, type }) => {
  let waterTable = [
    {
      id: 1,
      title: "Fresh Water (external)",
      quantity: null,
      specific_consumption: null,
      reference_value: null,
      deviation: null,
    },
    {
      id: 2,
      title: "Fresh Water (own)",
      quantity: null,
      specific_consumption: null,
      reference_value: null,
      deviation: null,
    },
    {
      id: 3,
      title: "Fresh Water (commercial)",
      quantity: null,
      specific_consumption: null,
      reference_value: null,
      deviation: null,
    },
    {
      id: 4,
      title: "Fresh Water (sanitary)",
      quantity: null,
      specific_consumption: null,
      reference_value: null,
      deviation: null,
    },
  ];

  const navigate = useNavigate();

  const [waterData, setWaterData] = useState({
    id: details?.id,
    selectedAsset: details?.location_id,
    unit: details?.unit,
  });

  const [consumptionDetail, setConsumptionDetail] = useState(
    details?.details ? details?.details : [...waterTable]
  );
  const [locations, setLocations] = useState([]);
  const [entity, setEntity] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState(details?.records || []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setWaterData({ ...waterData, [name]: value });
  };

  const requestLocation = async () => {
    try {
      const entity = await fethLocations();
      setLocations(entity.data);
    } catch (error) {
      errorToast(error.message);
    }
  };

  const requestMonths = async () => {
    try {
      if (entity) {
        const response = await fethLocationsValues(entity);
        setValues(response.data.entities);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const handelEntityChange = async (e) => {
    const { name, value } = e.target;
    const entity = name.split("-");
    const month = entity[0];
    const year = entity[1];
    const index = values.findIndex(
      (item) => item.months === month && item.years === year
    );
    let tempValues = [...values];
    tempValues[index].values = value;
    setValues(tempValues);
  };

  const handelSave = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    let isValid = true;
    values.forEach((item) => {
      if (
        item.values === "" ||
        item.values === null ||
        item.values === undefined
      ) {
        isValid = false;
      }
    });

    if (!isValid) {
      errorToast("All fields are required!");
    } else {
      let formattedArray = await generateArray(values);

      // eslint-disable-next-line
      let payload = {
        location_id: `${waterData.selectedAsset}`,
        unit: waterData.unit,
        years: formattedArray.years,
        months: formattedArray.months,
        values: formattedArray.values,
      };

      try {
        if (type === "update") {
          let updatePayload = { ...payload };
          updatePayload.consumption = consumptionDetail;
          await updateWaterConsumption(waterData?.id, updatePayload);
          successToast("Updated Successfully");
          navigate("/water");
        } else {
          const apiResponse = await saveWaterConsumption(payload);
          // create payload for details
          const detailsPayload = {
            water_id: `${apiResponse.data}`,
            consumption: consumptionDetail,
          };

          await saveWaterConsumptionDetail(detailsPayload);
          successToast("Created Successfully");
          navigate("/water");
        }
      } catch (error) {
        errorToast(error.message);
      }
    }
  };

  const handelDetailChange = async (e) => {
    const { name, value, id } = e.target;
    const updateItems = consumptionDetail.map((entity) => {
      // eslint-disable-next-line
      if (entity.id == id) {
        return { ...entity, [name]: value };
      } else {
        return entity;
      }
    });

    await setConsumptionDetail(updateItems);
  };

  useEffect(() => {
    requestMonths();
    // eslint-disable-next-line
  }, [entity]);

  useEffect(() => {
    requestLocation();
  }, []);

  return (
    <form name="waterData">
      <Stack
        direction="row"
        gap="16px"
        alignItems="center"
        className={styles.pageTitle}
      >
        <Box className={styles.backArrow}>
          <ArrowBack onClick={() => navigate("/water")} />
        </Box>
        <Stack direction="row" gap="16px" alignItems="center">
          <Typography className={styles.title}>Water Consumption</Typography>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99935 1.66663C5.39935 1.66663 1.66602 5.39996 1.66602 9.99996C1.66602 14.6 5.39935 18.3333 9.99935 18.3333C14.5993 18.3333 18.3327 14.6 18.3327 9.99996C18.3327 5.39996 14.5993 1.66663 9.99935 1.66663ZM10.8327 14.1666H9.16602V12.5H10.8327V14.1666ZM10.8327 10.8333H9.16602V5.83329H10.8327V10.8333Z"
              fill="#0F333D"
            />
          </svg>
        </Stack>
      </Stack>

      <div className={styles.inputFieldContainer}>
        <FormControl>
          <InputLabel id="demo-category-label">Select Asset</InputLabel>
          <Select
            labelId="demo-category-label"
            name="selectedAsset"
            value={waterData.selectedAsset || ""}
            onChange={handleChange}
            input={<OutlinedInput label="Select Asset" />}
            MenuProps={MenuProps}
            error={submitted && !waterData.selectedAsset}
            helpertext={
              submitted && !waterData.selectedAsset ? "Required field" : ""
            }
          >
            {locations.map((location) => (
              <MenuItem
                key={location?.id}
                value={location?.id}
                onClick={() => setEntity(location?.id)}
              >
                {location?.location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="unit"
          name="unit"
          value={waterData.unit || ""}
          onChange={handleChange}
          error={submitted && !waterData.unit}
          helpertext={submitted && !waterData.unit ? "Required field" : ""}
        />
      </div>

      <Typography className={styles.enterMonthly} mb={2}>
        Enter your monthly value
      </Typography>
      {values.length !== 0 && (
        <Box className={styles.gridContainer}>
          {values.map((item, index) => (
            <TextField
              label={
                moment(item.months, "MMMM").format("MMM") + "-" + item.years
              }
              sx={{ width: "100%" }}
              name={`${item.months}-${item.years}`}
              type="number"
              value={item?.values}
              onChange={handelEntityChange}
              key={index}
            />
          ))}
        </Box>
      )}
      <TableContainer component={Paper} sx={{ margin: "32px 0" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: theme.palette.esgblueblack.main }}>
              <TableCell className={styles.tableHead}>
                water consumption
              </TableCell>
              <TableCell className={styles.tableHead}>
                quantity in 2022 [m3]
              </TableCell>
              <TableCell className={styles.tableHead}>
                specific consumption [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>
                reference value from 2021 [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>deviation [%]</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consumptionDetail.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={styles.tableCell}>
                  {item?.title}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    id={item?.id.toString()}
                    value={item.quantity || ""}
                    name="quantity"
                    onChange={(e) => handelDetailChange(e)}
                    type="number"
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    id={item?.id.toString()}
                    value={item.specific_consumption || ""}
                    name="specific_consumption"
                    type="number"
                    onChange={(e) => handelDetailChange(e)}
                    size="small"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    id={item?.id.toString()}
                    value={item.reference_value || ""}
                    name="reference_value"
                    type="number"
                    onChange={(e) => handelDetailChange(e)}
                    size="small"
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TextField
                    id={item?.id.toString()}
                    value={item.deviation || ""}
                    name="deviation"
                    type="number"
                    onChange={(e) => handelDetailChange(e)}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end" gap="16px">
        <Button variant="outlined" onClick={() => navigate("/water")}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          className={styles.btnNext}
          onClick={(e) => handelSave(e)}
        >
          {type === "update" ? "Update" : "Save"}
        </Button>
      </Stack>
    </form>
  );
};

export default AddWaterDetails;
