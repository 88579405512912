/** @format */

import axios from "axios";
import config from "../../../config/config.json";
import { headers } from "../../helpers/helper";

const PREFIX = "carbon/datavault/";

export const getDataVault = async (tab, type) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${
        config.API_URL + PREFIX
      }governance/getQuestions?tab=${tab}&type=${type}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || "Oops some error occured!");
  }
};

export const postDataVault = async (param) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}governance/updateAnswer`,
      param,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || "Oops some error occured!");
  }
};
