/** @format */

import React, { useState, useRef, useEffect } from "react";
import styles from "../../pages/scope3/addScope3Details.module.css";
import {
  ExpandMore,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { errorToast, successToast } from '../../utils/alerts';

const ScopeRow = ({ scope, updateRows, removeRows, setSubmit}) => {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [isEditMode, setIsEditMode] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [entity, setEntity] = useState({ ...scope });
  const [updatedIds, setUpdatedIds] = useState([]);
  const valueRefs = useRef([]);
  const unitRefs = useRef([]);
  const emissionRefs = useRef([]);

  useEffect(() => {
    valueRefs.current = valueRefs.current.slice(0, entity.divisions.length);
    unitRefs.current = unitRefs.current.slice(0, entity.divisions.length);
    emissionRefs.current = emissionRefs.current.slice(0, entity.divisions.length);
    // eslint-disable-next-line
  }, []);

// eslint-disable-next-line
  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleAccordionCollapse = (panel) => (event, expanded) => {
    console.log(isExpanded === panel);
    setIsExpanded(expanded ? panel : false);
  };

  const handelDivisionChange = async (e, index) => {
    const { id, name, value } = e.target;

    setEntity({ ...entity, divisions: [ ...entity.divisions.slice(0, index), { ...entity.divisions[index], [name]: value }, ...entity.divisions.slice(index + 1) ] });

    setUpdatedIds(prev => (updatedIds.includes(parseInt(id)) ? prev : [...prev, parseInt(id)]));

  };

  const validateKeys = async (objects, keysToCheck) => {
    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      const missingKey = keysToCheck.find( key => obj[key] === undefined || obj[key] === null);
  
      if (missingKey) {
        return { index: i, missingKey, id: obj.division_id };
      }
    }
  
    return null;
  };

  const moveToParent = async(e) => {
    try{
      e.preventDefault();
      const data = entity.divisions.filter(({id}) => updatedIds.includes(id));
      console.log("data", data);
      // this means we need to delete this if exists
      if(data.length === 0) {
        removeRows(entity.id);
        successToast('Updated Successfully!');
      }else{

        const formattedPayload = data.map((item) => {
          return {
            division_id: item?.id || null,
            category_id: entity?.id || null,
            value: item?.value || null,
            unit: item?.selectedUnit || null,
            conversion: item?.conversion || null,
            emission: item?.emission || null,
            days: item?.days || null,
            people: item?.people || null
          }
        })      
        const isValid = await validateKeys(formattedPayload, [ "value", "conversion", "unit", "emission"]);
        if(isValid) {
          // fetch exact index
          const index = await entity.divisions.findIndex((item) => item.id === isValid.id);
  
          if(isValid.missingKey === 'value') {
            const inputField =  valueRefs.current[index].querySelector('input');
            inputField.focus();
          }else if(isValid.missingKey === 'selectedUnit') {
            const inputField =  unitRefs.current[index].querySelector('input');
            inputField.focus();
          }else{
            const inputField =  emissionRefs.current[index].querySelector('input');
            inputField.focus();
          }
          errorToast(`${isValid.missingKey[0].toUpperCase() + isValid.missingKey.slice(1)} is required at row ${index + 1}`);
        }else{
          updateRows(formattedPayload);
          setSubmit(false);
          successToast('Updated Successfully!');
        }
      }


    }catch(error) {
      errorToast(error.message)
    }
    
  }

  const handleClearValue = async() => {
    const formattedPayload = await entity.divisions.map((item) => {
      return {
        ...item,
        value: null,
        emission: null,
        selectedUnit: null
      }
    });
    let data = {
      ...entity,
      divisions: formattedPayload
    }
    setEntity(data);
    successToast('Cleared Successfully!');
    setUpdatedIds([]);
  }

  return (
    <Box className={styles.accordion} key={entity.id}>
      <Accordion
        expanded={isExpanded === entity.id}
        elevation={0}
        className={styles.contaner}
        onChange={handleAccordionCollapse(entity.id)}
      >
        <AccordionSummary
          expandIcon={
            isExpanded === entity.id ? <ExpandMore /> : <KeyboardArrowRight />
          }
          aria-controls="panel1a-content"
          id="transportation"
          className={styles.summary}
        >
          <Typography className={styles.accordionTitle}>
            {entity.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table className={styles.tableContainer}>
            <TableHead>
              <TableRow className={styles.tableHeadRow}>
                <TableCell className={styles.tableHeadCell}>Division</TableCell>
                <TableCell className={styles.tableHeadCell}>Value</TableCell>
                {scope.id === 77 && (
                  <>
                    <TableCell className={styles.tableHeadCell}>People</TableCell>
                    <TableCell className={styles.tableHeadCell}>No. of Days</TableCell>
                  </>
                )}
                <TableCell className={styles.tableHeadCell}>Unit</TableCell>
                <TableCell className={styles.tableHeadCell}>Conversion</TableCell>
                <TableCell className={styles.tableHeadCell}>Emissions</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {entity.divisions.map((data, index) => (
                <TableRow key={data.id} className={styles.tableBodyRow}>
                  <TableCell className={styles.headColl}>{data.name}</TableCell>
                  <TableCell className={styles.tableBodyCell}>
                    <TextField
                      label="Enter Value"
                      size="small"
                      name="value"
                      type="number"
                      autoFocus
                      value={data.value || ''}
                      id={data.id}
                      ref={(el) => (valueRefs.current[index] = el)}
                      onChange={(e) => handelDivisionChange(e, index)}
                    />
                  </TableCell>
                  {scope.id === 77 && (
                    <>
                      <TableCell className={styles.tableBodyCell}>
                          <TextField
                            label="Enter Value"
                            size="small"
                            name="people"
                            type="number"
                            autoFocus
                            value={data.people || ''}
                            id={data.id}
                            onChange={(e) => handelDivisionChange(e, index)}
                          />
                      </TableCell>
                      <TableCell className={styles.tableBodyCell}>
                        <TextField
                          label="Enter Value"
                          size="small"
                          name="days"
                          type="number"
                          autoFocus
                          value={data.days || ''}
                          id={data.id}
                          onChange={(e) => handelDivisionChange(e, index)}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell className={styles.tableBodyCell}>
                    <FormControl size="small">
                      <InputLabel id="demo-category-label">
                        Select unit
                      </InputLabel>
                      <Select
                        labelId="demo-category-label"
                        name="selectedUnit"
                        value={data?.selectedUnit || ''}
                        id={data.id}
                        ref={(el) => (unitRefs.current[index] = el)}
                        input={<OutlinedInput label="Select unit" />}
                        onChange={(e) => {
                          let tempEvent = {
                            target: {
                              name: "selectedUnit",
                              id: data.id,
                              value: e.target.value,
                            },
                          };
                          handelDivisionChange(tempEvent, index);
                        }}
                        MenuProps={MenuProps}
                        sx={{ minWidth: "200px" }}
                      >
                        {data.unit.split(",").map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={styles.tableBodyCell}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      padding="16px"
                    >

                    <TextField
                      label="Enter Conversion"
                      size="small"
                      fullWidth
                      name="conversion"
                      type="text"
                      autoFocus
                      value={data.conversion || ''}
                      id={data.id}
                      onChange={(e) => handelDivisionChange(e, index)}
                      // ref={(el) => (valueRefs.current[index] = el)}
                      // onChange={(e) => handelDivisionChange(e, index)}
                    />
                      {/* <Typography variant="div">{data.conversion}</Typography> */}
                      {/* <IconButton
                        className={styles.editBtn}
                        onClick={handleEditClick}
                      >
                        <ModeEditOutlineOutlined />
                      </IconButton> */}
                    </Stack>
                  </TableCell>
                  <TableCell className={styles.tableBodyCell}>
                    <TextField
                      size="small"
                      label="Emission"
                      name="emission"
                      ref={(el) => (emissionRefs.current[index] = el)}
                      value={data.emission || ''}
                      id={data.id}
                      onChange={(e) => handelDivisionChange(e, index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="16px"
            marginTop="24px"
          >
            <Button variant="outlined" onClick={() => handleClearValue()}>
              Clear
            </Button>
            <Button
              variant="contained"
              type="submit"
              className={styles.btnNext}
              onClick={(e) => moveToParent(e)}
            >
              Update
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ScopeRow;
