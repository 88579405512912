/** @format */

import { Box, Typography } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";
import styles from "./charts.module.css";

const BarChart = ({ chartData, yAxisTitle, chartTitle, aspectRatio }) => {
  const options = {
    aspectRatio: aspectRatio,
    scales: {
      x: {
        title: {
          display: true,
          text: "Years",
          color: "#577077",
          font: {
            fontFamily: "roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          },
          padding: {
            bottom: 30,
            top: 30,
          },
        },
        ticks: {
          suggestedMin: 2005,
          suggestedMax: 2025,
          stepSize: 1,
        },
      },

      y: {
        ticks: {
          min: 0,
          max: 200000,
          stepSize: 10000,
        },
        title: {
          display: true,
          text: yAxisTitle,
          color: "#577077",
          font: {
            fontFamily: "roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          },
          padding: {
            bottom: 30,
          },
        },
        type: "linear",
        display: true,
        position: "left",
        id: "bar-y-axis",
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Box className={styles.barChartContainer}>
      <Box className={styles.titleContainer}>
        <Typography
          variant="h5"
          className={styles.chartTitle}
          sx={{ height: "67px" }}
        >
          {chartTitle}
        </Typography>
      </Box>
      <Box className={styles.barChart}>
        <Bar data={chartData} options={options} />
      </Box>
    </Box>
  );
};

export default BarChart;
