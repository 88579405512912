/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [
    "Category 1",
    "Category 2",
    "Category 3",
    "Category 4",
    "Category 5",
    "Category 6",
  ],
  divisions: [
    "Division 1",
    "Division 2",
    "Division 3",
    "Division 4",
    "Division 5",
    "Division 6",
  ],
  locations: [
    "Location 1",
    "Location 2",
    "Location 3",
    "Location 4",
    "Location 5",
    "Location 6",
  ],
  scopes: [
    "Energy consumption",
    "Scope 1",
    "Scope 2",
    "Scope 3",
    "Water",
    "Waste",
  ],
  actionPlanList: [],
};

export const targetSlice = createSlice({
  name: "target",
  initialState,
  reducers: {
    ADDPLAN: (state, action) => {
      state.actionPlanList.push(action);
    },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { ADDPLAN /*decrement, incrementByAmount*/ } = targetSlice.actions;

export default targetSlice.reducer;
