/** @format */

import React, { useRef, useState } from "react";
import DataVault from "../DataVault";
import { Box, Button, Stack, Typography } from "@mui/material";
import styles from "../governance/leadershipCommitments.module.css";
import EsgiChart from "../../../components/EsgiChart";
import { Upload } from "@mui/icons-material";
import { successToast } from "../../../utils/alerts";
const reportList = [
  "ESG Report",
  "ESG Risk Report",
  "Supply Chain ESG Risk Management",
  "SDGs",
  "Carbon Footprint",
  "Governance",
];
const ReportingIntelligence = () => {
  // eslint-disable-next-line
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0].name);

    successToast("File uploaded successfully !");
  };
  // eslint-disable-next-line
  const [percentage, setPercentage] = useState(20);
  const fileInputRef = useRef(null);
  const handleFileSelect = () => {
    fileInputRef.current.click();
  };
  return (
    <DataVault>
      <Stack gap="16px" className={styles.leadershipContainer} pb={3}>
        <Typography variant="h5" className={styles.pageHeading}>
          ESG Reporting Intelligence
        </Typography>
        <Typography variant="p" className={styles.explaination}>
          This will be a brief explanation of the Corporate ESG section. Lorem
          Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages,
        </Typography>
        <Typography className={styles.questionCount} pb={1}>
          {percentage}% Complete
        </Typography>
        <Box p={3} sx={{ boxShadow: "0px 4px 24px 0px rgba(15, 51, 61, 0.1)" }}>
          <Typography variant="p" className={styles.question} mb={1}>
            What is your ESGI Score?
          </Typography>
          <EsgiChart />
          <Stack gap={2}>
            <Typography variant="p" className={styles.question} mt={2}>
              What is your ESGI Score?
            </Typography>
            {reportList.map((item) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                bgcolor="#F6F7FB"
                p={2}
                sx={{ borderRadius: "8px" }}
                key={item}
              >
                <Typography className={styles.reportLabel}>{item}</Typography>
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <Button
                    startIcon={<Upload color="primary" />}
                    sx={{ marginLeft: "8px" }}
                    onClick={handleFileSelect}
                  >
                    Choose File
                  </Button>
                </>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Stack>
    </DataVault>
  );
};

export default ReportingIntelligence;
