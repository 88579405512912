import {
    Box,
    Button,
    IconButton,
    Modal,
    Stack,
    TextField,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    OutlinedInput,
  } from "@mui/material";
  import React, { useState } from "react";
  import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
  import { updateLocation } from "../services/private/settings/SettingsService";
  import "../components/editModal.css";
  import dayjs from 'dayjs';
  import { successToast } from "../utils/alerts";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UpdateLocationModal = ({
    addModal,
    setAddModal,
    selectedLocation,
    setSelectedLocation,
    requestServer,
    headAvailable
}) => {

    const [locationType, setLocationType] = useState(null);
    
    const handelOnChange = async (e) => {
      const { name, value } = e.target;

      setSelectedLocation((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handelCloseModal = async () => {
      setSelectedLocation({
        name: null,
        type: null,
        start: null,
        end: null,
      });
      setLocationType(null);
      setAddModal(false);
    };
  
    const handleLocationType = (e) => {
      setLocationType(e.target.value);
    };

    const handelSaveBtn = async () => {

        let payload = {
            location: selectedLocation.location,
            start: selectedLocation.start,
            end: selectedLocation.end,
            type: locationType === 'head' ? '0' : '1'
        }
        try{
            await updateLocation(selectedLocation?.id, payload);
            handelCloseModal();
            requestServer();
            successToast('Updated Successfully')
        }catch(error){
            successToast(error.message)
        }
        
    }

  return (
    <Modal
    open={addModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style} className="edit-box-modal">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #E8EBF2"
        marginBottom="16px"
      >
        <Typography id="modal-modal-title" variant="h6">
          Edit Location
        </Typography>
        <IconButton onClick={() => handelCloseModal()}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="CancelFilled">
              <path
                id="Vector"
                d="M16.0013 2.66675C8.62797 2.66675 2.66797 8.62675 2.66797 16.0001C2.66797 23.3734 8.62797 29.3334 16.0013 29.3334C23.3746 29.3334 29.3346 23.3734 29.3346 16.0001C29.3346 8.62675 23.3746 2.66675 16.0013 2.66675ZM22.668 20.7867L20.788 22.6667L16.0013 17.8801L11.2146 22.6667L9.33464 20.7867L14.1213 16.0001L9.33464 11.2134L11.2146 9.33342L16.0013 14.1201L20.788 9.33342L22.668 11.2134L17.8813 16.0001L22.668 20.7867Z"
                fill="#82959C"
              />
            </g>
          </svg>
        </IconButton>
      </Stack>
      <Stack gap="24px">
        <TextField
          label="Enter Your Location"
          sx={{ width: "100%", fontSize: "14px" }}
          className="modal--input"
          name="location"
          value={selectedLocation?.location || ''}
          onChange={(e) => handelOnChange(e)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={dayjs(selectedLocation?.start)}
              onChange={(e) => {
                
                let tempEvent = {
                  target: {
                    name: "start",
                    value: e,
                  },
                };
                handelOnChange(tempEvent);
              }}
              sx={{
                width: "100%",
              }}
              label="Financial Year Start From"
            />
          </DemoContainer>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
                value={dayjs(selectedLocation?.end)}
                onChange={(e) => {
                    let tempEvent = {
                    target: {
                        name: "end",
                        value: e,
                    },
                    };
                    handelOnChange(tempEvent);
                }}
                sx={{
                    width: "100%",
                }}
                label="Financial Year End" 
            />
          </DemoContainer>
        </LocalizationProvider>
        {(headAvailable ||  selectedLocation.type === 'Headquarter') &&  (
          <FormControl sx={{ width: "100%", fontFamily: "inherit" }}>
            <InputLabel id="demo-scope-label">Select Location Type</InputLabel>
            <Select
              labelId="demo-scope-label"
              id="demo-scope"
              value={locationType || (selectedLocation.type === "Headquarter" ? "head" : "other")}
              name="type"
              onChange={(e) => handleLocationType(e)}
              input={<OutlinedInput label="Select Location Type" />}
              MenuProps={MenuProps}
            >
              <MenuItem value={"head"} id="input-dropdown">
                Headquarter
              </MenuItem>
              <MenuItem value={"other"} id="input-dropdown">
                Other
              </MenuItem>
            </Select>
          </FormControl>
        )}

      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        gap="16px"
        alignItems="center"
      >
        <Button
          onClick={() => handelCloseModal()}
          variant="contained"
          className="btn--cancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={() => handelSaveBtn()}
        >
          Save
        </Button>
      </Stack>
    </Box>
  </Modal>
  )
}

export default UpdateLocationModal