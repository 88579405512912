/** @format */

import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./assetManagement.module.css";
import { DeleteOutline, OpenInNew } from "@mui/icons-material";
import theme from "../theme";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../assets/icons/EditIcon";
const tableData = [
  {
    id: 1,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
  {
    id: 2,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
  {
    id: 3,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
  {
    id: 4,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
  {
    id: 5,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
  {
    id: 6,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
  {
    id: 7,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
  {
    id: 8,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: "90,652",
    col6: "Attachment",
    col7: "471,029",
  },
];
const OurPortfolio = () => {
  const navigate = useNavigate();
  return (
    <Stack className={styles.container} gap={2} p={3}>
      <Typography variant="h5" className={styles.pageTitle}>
        Our Portfolio
      </Typography>
      <Typography variant="body" className={styles.description}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages,
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <TextField
          variant="outlined"
          label="Search"
          placeholder="Name, email, etc..."
          size="small"
        />
        <Button
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={() => navigate("add-new-asset")}
        >
          Add New Asset
        </Button>
      </Stack>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: theme.palette.esgblueblack.main }}>
              <TableCell className={styles.tableHeadCell}>Asset Name</TableCell>
              <TableCell className={styles.tableHeadCell}>
                Property Type
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Country</TableCell>
              <TableCell className={styles.tableHeadCell}>City/State</TableCell>
              <TableCell className={styles.tableHeadCell}>Size (M2)</TableCell>
              <TableCell className={styles.tableHeadCell}>
                Building Ceritfication
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Valuation</TableCell>
              <TableCell className={styles.tableHeadCell} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item) => (
              <TableRow key={item.id}>
                <TableCell className={styles.tableBodyCell}>
                  {item.col1}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col2}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col3}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col4}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col5}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col6}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  ${item.col7}
                </TableCell>
                <TableCell>
                  <Stack direction="row" gap={5} justifyContent="flex-end">
                    <IconButton
                      size="small"
                      sx={{ color: theme.palette.esgblueblack.main }}
                    >
                      <OpenInNew />
                    </IconButton>
                    <IconButton size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ color: theme.palette.esgblueblack.main }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default OurPortfolio;
