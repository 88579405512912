/** @format */

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
} from "@mui/material";

const DeleteModal = ({
  open,
  handelClose,
  label,
  description,
  deleteRow,
  id,
}) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handelDelete = async () => {
    await deleteRow(id);
    await handelClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handelClose()}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{ borderBottom: "1px solid #ddd", marginBottom: "24px" }}
      >
        {label}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handelClose()}>Cancel</Button>
        <Button color="error" onClick={() => handelDelete()}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
