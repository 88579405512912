/** @format */

import axios from "axios";
import config from "../../../config/config.json";
import { headers } from "../../helpers/helper";

const PREFIX = "carbon/scope/";

export const requestConfig = async (scope) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}fetchConfig/${scope}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || "Oops some error occured!");
  }
};

export const requestScopeValue = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}getScopesValue/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || "Oops some error occured!");
  }
};

export const addScope = async (payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.post(`${config.API_URL + PREFIX}addScopeValues`, payload, {
            headers: auth
        }); 
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const updateScope = async (id, payload) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.put(`${config.API_URL + PREFIX}editScopeValues/${id}`, payload, {
            headers: auth
        }); 
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const requestScopeList = async (fy_start, fy_end, scope) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.get(`${config.API_URL + PREFIX}fetchScopeList?fy_start=${fy_start}&fy_end=${fy_end}&scope=${scope}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const removeScope = async (id) => {
    try{
        const auth = await headers();
        const apiResponse = await axios.delete(`${config.API_URL + PREFIX}deleteScope/${id}`, {
            headers: auth
        });
        return apiResponse.data;
    }catch(err) {
        throw new Error(err.response.data.message || 'Oops some error occured!');
    }
};

export const removeCategory = async (payload) => {
  try{
      const auth = await headers();
      const apiResponse = await axios.post(`${config.API_URL + PREFIX}deleteCategory`, payload, {
          headers: auth
      });
      return apiResponse.data;
  }catch(err) {
      throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};
