import toast from "react-hot-toast";

export const successToast = async(message) => {
    toast.success(message, {
        duration: 1000,
        position: 'top-center',
        style: {
          minWidth: '200px',
          fontFamily: 'roboto',
          fontSize: '16px',
          fontWeight: '160',
        },
        className: '',
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
}


export const errorToast = async(message) => {
    toast.error(message, {
        duration: 1000,
        position: 'top-center',
        style: {
          minWidth: '200px',
          fontFamily: 'roboto',
          fontSize: '16px',
          fontWeight: '160',
        },
        className: '',
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
}

export const promiseToast = async(promiseData, message) => {
 
  toast.promise(promiseData, {
    loading: 'Loading',
    success: message,
    error: (err) => err.message,
  }, {
      style: {
        minWidth: '200px',
        fontFamily: 'roboto',
        fontSize: '16px',
        fontWeight: '160',
      },
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      }  
  })
}