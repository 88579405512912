/** @format */

import { ArrowBack, Visibility } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../pages/dataVault/dataVault.module.css";
import classes from "./assetManagement.module.css";
import AssetNavBar from "../UI/AssetNavBar";
import theme from "../theme";
// import { useSelector } from "react-redux";

const tableData = [
  {
    id: 1,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
  {
    id: 2,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
  {
    id: 3,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
  {
    id: 4,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
  {
    id: 5,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
  {
    id: 6,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
  {
    id: 7,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
  {
    id: 8,
    col1: 20796,
    col2: "Robert Fox",
    col3: 1,
    col4: 536,
    col5: "5560 sqFt",
    col6: "8 Sep, 2020",
    col7: "1 Feb, 2020",
    col8: "$3,443.00",
  },
];

const ViewAssets = () => {
  const navigate = useNavigate();

  return (
    <Stack className={styles.dataVaultContainer}>
      <AssetNavBar />
      <Stack p={3} gap={3} className={classes.tenantContainer}>
        <Stack direction="row" gap={2}>
          <IconButton
            size="small"
            className={styles.backArrow}
            onClick={() => navigate("/asset-management")}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.pagetitle}>
            Building Name
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            variant="outlined"
            size="small"
            label="Search"
            placeholder="Name, email, etc..."
          />
          <Button variant="contained" sx={{ color: "#fff" }}>
            Add New Tenant
          </Button>
        </Stack>
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.container}
        >
          <Table>
            <TableHead>
              <TableRow
                className={classes.tableHeadRow}
                sx={{ bgcolor: theme.palette.esgblueblack.main }}
              >
                <TableCell className={classes.tableHeadCell}></TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Tenant ID
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Tenant name
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Floor</TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Suite No.
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Area Leased
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Commencement Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Expiry Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Rent</TableCell>
                <TableCell className={classes.tableHeadCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((item) => (
                <TableRow className={classes.tableBodyRow} key={item.id}>
                  <TableCell className={classes.tableBodyCell}>
                    <Checkbox size="small" />
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col1}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col2}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col3}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col4}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col5}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col6}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col7}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.col8}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    <IconButton
                      size="small"
                      onClick={() => navigate(`view-tenants/${item.id}`)}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default ViewAssets;
