/** @format */

import React from "react";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import BarChart from "../charts/BarChart";
import { PieData, UserData } from "../../Data";
import { Box, Stack } from "@mui/material";
import DoughnutChart from "../charts/Doughnut";

const ReportingAnalytics = () => {
  const userData = {
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "User Gain",
        type: "line",
        data: UserData.map((data) => data.userGain),
        borderColor: "#6DB3B5",
        fill: true,
        tension: 0,
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      {
        label: false,
        type: "bar",
        data: UserData.map((data) => data.userGain),
        backgroundColor: "#165BAA",
        barThickness: 32,
      },
    ],
  };
  const pieData = {
    labels: PieData.map((data) => data.year),
    datasets: [
      {
        label: "User Lost",
        type: "doughnut",
        data: PieData.map((data) => data.userLost),
        backgroundColor: ["#A155B9", "#165BAA", "#F765A3", "#16BFD6"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <Box pt={3}>
      <HorizontalBarChart />
      <BarChart
        chartData={userData}
        yAxisTitle="C0-2-Emissions [tC02e]"
        chartTitle="Emissions Reduction Targets.(C02E)"
        aspectRatio={5}
      />
      <Stack direction="row" gap="24px">
        <BarChart
          chartData={userData}
          yAxisTitle="Energy Consumption [MWhs]"
          chartTitle="Yearly Energy Consumption"
          aspectRatio={3}
        />
        <DoughnutChart
          chartData={pieData}
          type="Total Energ Consumptiony"
          chartHeight={250}
        />
      </Stack>
      <Stack direction="row" gap="24px">
        <BarChart
          chartData={userData}
          yAxisTitle="Water Consumption [m3]"
          chartTitle="Yearly Water Consumption"
          aspectRatio={3}
        />
        <DoughnutChart
          chartData={pieData}
          type="Total Water Consumption"
          chartHeight={250}
        />
      </Stack>

      <BarChart
        chartData={userData}
        yAxisTitle="Waste Consumption [m3]"
        chartTitle="Yearly Waste Consumption"
        aspectRatio={5}
      />

      <Stack direction="row" spacing={3} sx={{ width: "100%" }}>
        <div
          style={{
            flexGrow: 1,
            borderRadius: "6px",
            backgroundColor: "#f6f7fb",
          }}
        >
          <DoughnutChart
            chartData={pieData}
            type="Total Waste Consumption"
            chartHeight={300}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            borderRadius: "6px",
            backgroundColor: "#f6f7fb",
          }}
        >
          <DoughnutChart
            chartData={pieData}
            type="Total Waste Consumption"
            chartHeight={300}
          />
        </div>
      </Stack>
    </Box>
  );
};

export default ReportingAnalytics;
