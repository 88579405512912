/** @format */

import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./assetManagement.module.css";
import theme from "../theme";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const tableData = [
  {
    id: 1,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
  {
    id: 2,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
  {
    id: 3,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
  {
    id: 4,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
  {
    id: 5,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
  {
    id: 6,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
  {
    id: 7,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
  {
    id: 8,
    col1: "Asset Name",
    col2: "Retail: High Street",
    col3: "Czech Republic",
    col4: "City Name",
    col5: 1250,
  },
];
const Tenants = () => {
  const navigate = useNavigate();
  return (
    <Stack className={styles.container} gap={2} p={3}>
      <Typography variant="h5" className={styles.pageTitle}>
        Tenants
      </Typography>
      <Typography variant="body" className={styles.description}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages,
      </Typography>
      <Box>
        <TextField
          variant="outlined"
          label="Search"
          placeholder="Name, email, etc..."
          size="small"
        />
      </Box>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow
              className={styles.tableHeadRow}
              sx={{ bgcolor: theme.palette.esgblueblack.main }}
            >
              <TableCell className={styles.tableHeadCell}>Asset Name</TableCell>
              <TableCell className={styles.tableHeadCell}>
                Property Type
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Country</TableCell>
              <TableCell className={styles.tableHeadCell}>City/State</TableCell>
              <TableCell className={styles.tableHeadCell}>
                No. of Tenants
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item) => (
              <TableRow className={styles.tableBodyRow} key={item.id}>
                <TableCell className={styles.tableBodyCell}>
                  {item.col1}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col2}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col3}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col4}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item.col5}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`view-assets/${item.id}`)}
                    sx={{ color: theme.palette.esgblueblack.main }}
                  >
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Tenants;
