import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { loginService } from '../services/public/LoginService';
import { setStore } from '../utils/UniversalFunction';
import { useDispatch } from "react-redux";
import { login } from "../store/authSlice";
import { errorToast } from '../utils/alerts';
const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();

    useEffect(()=> {
        serverRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const serverRequest = async () => {
        try{
            const apiReponse = await loginService.login(token);
            const payload = {
                id: apiReponse.data.userId,
                email: apiReponse.data.email,
                firstName: apiReponse.data.firstName,
                lastName: apiReponse.data.lastName,
                role: apiReponse.data.role,
                token: token,
                logo: apiReponse.data.logo
            }
            // save to local storage
            await setStore('user', payload)
            dispatch(login(payload));
            navigate('/');

        }catch(err){
            navigate('/unauthorised');
            errorToast(err.message)
        }

    }
    return (
    <h1>Verifying...</h1>
  )
}

export default Login