/** @format */

import React, { useState } from "react";
import AssetNavBar from "../../components/UI/AssetNavBar";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "../../pages/dataVault/dataVault.module.css";
import { UploadFile } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const retails = [
  { title: "Retail: Retail Centers: Shopping Center" },
  { title: "Retail: Retail Centers: Strip Mall" },
  { title: "Retail: Retail Centers: Lifestyle Center" },
  { title: "Retail: Retail Centers: Warehouse" },
  { title: "Retail: Restaurants/Bars" },
  { title: "Retail: Other" },
  { title: "Office: Corporate: Low-Rise Office" },
  { title: "Office: Corporate: Mid-Rise Office" },
  { title: "Office: Corporate: High-Rise Office" },
  { title: "Office: Medical Office" },
  { title: "Office: Business Park" },
  { title: "Office: Other" },
  { title: "Industrial: Distribution Warehouse" },
  { title: "Industrial: Industrial Park" },
  { title: "Industrial: Manufacturing" },
  { title: "Industrial: Other" },
  { title: "Residential: Multi-Family: Low-Rise Multi-Family" },
  { title: "Residential: Multi-Family: Mid-Rise Multi-Family" },
  { title: "Residential: Multi-Family: High-Rise Multi-Family" },
  { title: "Residential: Family Homes" },
  { title: "Residential: Student Housing" },
  { title: "Residential: Retirement living" },
  { title: "Residential: Other" },
  { title: "Hotel" },
  { title: "Lodging, Leisure & Recreation: Indoor Arena" },
  { title: "Lodging, Leisure & Recreation: Fitness Center" },
  { title: "Lodging, Leisure & Recreation: Performing Arts" },
  { title: "Lodging, Leisure & Recreation: Swimming Center" },
  { title: "Lodging, Leisure & Recreation: Museum/Gallery" },
  { title: "Lodging, Leisure & Recreation: Other" },
  { title: "Education: School" },
  { title: "Education: University" },
  { title: "Education: Library" },
  { title: "Education: Other" },
  { title: "Technology/Science: Data Center" },
  { title: "Technology/Science: Laboratory/Life Sciences" },
  { title: "Technology/Science: Other" },
  { title: "Healthcare: Healthcare Center" },
  { title: "Healthcare: Senior Homes" },
  { title: "Healthcare: Other" },
  { title: "Mixed use: Office/Retail" },
  { title: "Mixed use: Office/Residential" },
  { title: "Mixed use: Office/Industrial" },
  { title: "Mixed use: Other" },
  { title: "Other: Parking (Indoors)" },
  { title: "Other: Self-Storage" },
  { title: "Other" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddNewAsset = () => {
  const [assetData, setAssetData] = useState({ id: "", assetName: "" });
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAssetData({ ...assetData, [name]: value });
  };
  const navigate = useNavigate();
  return (
    <Box className={styles.dataVaultContainer}>
      <AssetNavBar />
      <Box className={styles.assetContainer} p={3}>
        <Typography className={styles.pageHeading} mb={2} variant="h5">
          Edit Details
        </Typography>
        <Stack gap={3}>
          <Stack direction="row" gap={3}>
            <TextField
              label="Asset Name"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="assetName"
            />
            <FormControl sx={{ width: "50%", fontFamily: "inherit" }}>
              <InputLabel id="demo-property-label">Property Type</InputLabel>
              <Select
                labelId="demo-property-label"
                id="property-type"
                // value={targetDetails?.type || ''}
                name="property-type"
                onChange={handleChange}
                input={<OutlinedInput label="Property Type" />}
                MenuProps={MenuProps}
                size="small"
              >
                {retails.map((item) => (
                  <MenuItem
                    key={item.title}
                    value={item.title}
                    id="input-dropdown"
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={3}>
            <FormControl sx={{ width: "50%", fontFamily: "inherit" }}>
              <InputLabel id="demo-country-label">Country</InputLabel>
              <Select
                labelId="demo-country-label"
                id="demo-country"
                // value={targetDetails?.type || ''}
                name="type"
                onChange={handleChange}
                input={<OutlinedInput label="Country" />}
                MenuProps={MenuProps}
                size="small"
              >
                {[
                  "country 1",
                  "country 2",
                  "country 3",
                  "country 4",
                  "country 5",
                ].map((item) => (
                  <MenuItem key={item} value={item} id="input-dropdown">
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              label="Country"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="country"
            /> */}
            <TextField
              label="State / Province"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="State"
            />
          </Stack>
          <Stack direction="row" gap={3}>
            <TextField
              label="City"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="city"
            />
            <TextField
              label="Street Address"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="street"
            />
          </Stack>
          <Stack direction="row" gap={3}>
            <TextField
              label="Construction Year"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="constructionYear"
            />
            <TextField
              label="% of Ownership"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="ownership"
            />
          </Stack>
          <Stack direction="row" gap={3}>
            <TextField
              label="Gross Asset Value (GAV) $"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="gav"
            />
            <TextField
              label="Asset Size (GFA)"
              sx={{ width: "50%" }}
              onChange={handleChange}
              // required={true}
              size="small"
              name="gfa"
            />
          </Stack>
        </Stack>
        <Typography className={styles.pageHeading} mt={3} mb={2} variant="h6">
          Upload Supporting Attachment
        </Typography>
        <Typography variant="p" className={styles.listLabel}>
          Please provide financial evidence of asset Gross Asset Value of each
          asset. Financial evidence must sufficiently support each of the values
          including:
        </Typography>
        <ul className={styles.listItems}>
          <li className={styles.listItem}>
            Property Sub-Types represented in the portfolio;
          </li>
          <li className={styles.listItem}>
            Numbers of assets per Property Sub-Type;
          </li>
          <li className={styles.listItem}>Floor Area per Property Sub-Type</li>
        </ul>
        <Typography variant="p" className={styles.listLabel}>
          Select from the below to upload evidence for each asset:
        </Typography>
        <ul className={styles.listItems}>
          <li className={styles.listItem}>
            Full audited financial statements with reference to balance-sheet
            and relevant notes breaking down the entity’s portfolio
            characteristics;
          </li>
          <li className={styles.listItem}>
            Section in entity reporting to investors. Extracts that do not
            demonstrate the overall size of the portfolio will not be accepted;
          </li>
          <li className={styles.listItem}>
            Audit statements confirming the composition of the portfolio during
            the reporting year, including acquisitions and dispositions;
          </li>
          <li className={styles.listItem}>
            Signed statement from CEO or Senior Management confirming that the
            portfolio composition reported above is truthful, accurate and
            complete and it represents the entire portfolio during the reporting
            year. Statements signed by Assistant Fund Managers or Deputy Fund
            Managers are not sufficient. The statement should specifically list
            all property sub-types, total number of assets and floor area size
            by property sub-type; or
          </li>
          <li className={styles.listItem}>10K filings.</li>
        </ul>
        <Stack className={styles.importContainer}>
          <Typography variant="h5" className={styles.import}>
            Import Excel File
          </Typography>
          <Stack className={styles.importBox} alignItems="center" gap={1}>
            <UploadFile color="primary" sx={{ fontSize: 40 }} />
            <Stack direction="row" gap="4px" alignItems="center">
              <Typography variant="span" className={styles.clickToUpload}>
                Click to upload
              </Typography>
              <Typography variant="span" className={styles.drag}>
                or drag and drop
              </Typography>
            </Stack>
            <Typography variant="p" className={styles.fileType}>
              SVG, PNG, JPG or GIF (max. 3MB)
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Button variant="contained" sx={{ color: "white" }}>
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate("/asset-management")}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddNewAsset;
