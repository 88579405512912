/** @format */

import { Card, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../theme";
import styles from "./footerCard.module.css";
import GenerateCustomReportModal from "../../modals/GenerateCustomReportModal";
const FooterCard = () => {
  const [generateReport, setGenerateReport] = useState(false);
  return (
    <>
      <GenerateCustomReportModal
        open={generateReport}
        setOpen={setGenerateReport}
      />
      <Card
        sx={{
          backgroundColor: theme.palette.esgblueblack.main,
          color: "#fff",
          padding: "24px",
          // marginTop: "24px",
        }}
      >
        <div className={styles.cardLayout}>
          <Stack direction="row" gap="48px">
            <img
              src="/images/Logo.svg"
              alt="esg-logo"
              className={styles.esgLogoFooter}
            />
            <Divider
              orientation="vertical"
              className={styles.verticalDivider}
              flexItem
            />
            <Stack justifyContent="center" gap="4px">
              <Typography variant="h6" className={styles.footerHeadline}>
                Want a custom report from ESG?
              </Typography>
              <Typography
                variant="p"
                className={styles.footerLink}
                onClick={() => setGenerateReport(true)}
              >
                Click here to submit your request to generate the report
              </Typography>
            </Stack>
          </Stack>
          <Card sx={{ padding: "16px 16px 16px 32px", bgcolor: "#33525A" }}>
            <ul>
              <Stack direction="row" gap="24px">
                <Stack>
                  <li className={styles.listitem}>Carbon footprint</li>
                  <li className={styles.listitem}>
                    Emissions reduction targets
                  </li>
                </Stack>
                <Stack>
                  <li className={styles.listitem}>Carbon pricing</li>
                  <li className={styles.listitem}>Renewable energy usage</li>
                </Stack>
              </Stack>
            </ul>
          </Card>
        </div>
      </Card>{" "}
    </>
  );
};

export default FooterCard;
