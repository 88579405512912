import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import CardTargetItem from "../../components/CardTargetItem";
import { saveTarget } from "../../services/private/targets/TargetService";
import { useNavigate } from "react-router-dom";
import { updateTarget } from '../../services/private/targets/TargetService';
import { errorToast,promiseToast } from "../../utils/alerts";

const ReviewTarget = ({ handleBack, targetDetails, actions, type, setActions }) => {
  const navigate = useNavigate();

  const handelSave = async () => {

    if(type === 'update'){
      try{
        const formattedAction = actions.map((action) => {
          return action.name;
        });

        let payload = {
          type: targetDetails.type,
          shift: targetDetails.shift,
          shift_type: targetDetails.shift_type,
          shift_value: `${targetDetails.shift_value}`,
          achieve_by: targetDetails.achieve_by,
          location_id: `${targetDetails.location_id}`,
          assign_to: `${targetDetails.assign_to_id}`,
          actions: formattedAction,
        };

        if (
          targetDetails.type === "1" ||
          targetDetails.type === "2" ||
          targetDetails.type === "3"
        ) {
          let catDiv = {
            division_id: `${targetDetails.division_id}`,
            category_id: `${targetDetails.category_id}`,
          };
          payload = { ...payload, ...catDiv };
        }


        const apiResponse = updateTarget(targetDetails.id, payload);
        promiseToast(apiResponse, "Updated Successfully")
        apiResponse.then(() => {
          navigate("/targets");
        })
        
      }catch(error) {
        errorToast(error.message);
      }
    }else{
      try {
        const formattedAction = actions.map((action) => {
          return action.name;
        });

        let payload = {
          type: targetDetails.type,
          shift: targetDetails.shift,
          shift_type: targetDetails.shift_type,
          shift_value: targetDetails.shift_value,
          achieve_by: targetDetails.achieve_by.$d,
          location_id: `${targetDetails.location_id}`,
          assign_to: `${targetDetails.assign_to_id}`,
          actions: formattedAction,
        };

        if (
          targetDetails.type === "1" ||
          targetDetails.type === "2" ||
          targetDetails.type === "3"
        ) {
          let catDiv = {
            division_id: `${targetDetails.division_id}`,
            category_id: `${targetDetails.category_id}`,
          };
          payload = { ...payload, ...catDiv };
        }
        const apiResponse = saveTarget(payload);
        promiseToast(apiResponse, "Saved Successfully")
        apiResponse.then(() => {
          navigate("/targets");
          setActions([]);        
        })

      } catch (error) {
        errorToast(error.message);
      }  
    }
  };

  return (
    <Box>
      <CardTargetItem targetDetails={targetDetails} />
      <Stack direction="column" gap="8px">
        <Typography variant="p" className="list-heading">
          Action Plans
        </Typography>
        {actions.length ? (
          <ul className="action-list-items">
            {actions.map((plan) => (
              <li className="action-list-item" key={plan.id}>
                {plan.name}
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-action">
            Currently there is no plan to show. Please add plan !
          </p>
        )}
      </Stack>
      <Button
        variant="outlined"
        sx={{ mr: 1, color: "#33ae5e" }}
        onClick={handleBack}
      >
        Back
      </Button>
      <Button
        variant="contained"
        onClick={() => handelSave()}
        sx={{ color: "#fff" }}
      >
        {type === 'update' ? (
          'Update'
        ) : (
          'Save'
        )}
      </Button>
    </Box>
  );
};

export default ReviewTarget;
