/** @format */

import React, {useState, useEffect} from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TableFooter,
  Typography,
  // Stack,
  // Box,
} from "@mui/material";
import theme from "../../components/theme";
import FooterCard from "../../components/UI/FooterCard";
import styles from "./viewWaterConsumption.module.css";
import { requestWaterConsumptionDetails } from '../../services/private/water/WaterService';
import { useParams } from 'react-router-dom';
import { errorToast } from "../../utils/alerts";

const ViewWaterConsumption = () => {
  
  const { id } = useParams();
  const [detals, setDetails] = useState([]);
  const [total, setTotal] = useState({
    quantity: 0,
    specific_consumption: 0,
    reference_value: 0,
    deviation: 0 
  });

  const serverRequest = async () => {
    try{
      const response = await requestWaterConsumptionDetails(id);
      setDetails(response.data);
      calculateTotal(response.data.details);
    }catch(error){
      errorToast(error.message)
    }
  }

  const calculateTotal = async(items) => {

    const { sumQuantity, sumReferenceValue, sumSpecificConsumption, sumDeviation } = items.reduce((acc, item) => {
          if(item.quantity) acc.sumQuantity += parseInt(item.quantity);
          if(item.reference_value) acc.sumReferenceValue += parseInt(item.reference_value);
          if(item.specific_consumption) acc.sumSpecificConsumption += parseInt(item.specific_consumption);
          if(item.deviation) acc.sumDeviation += parseInt(item.deviation);
      return acc;
  }, { sumQuantity: 0, sumReferenceValue: 0, sumSpecificConsumption: 0, sumDeviation: 0});
      setTotal({
        quantity: sumQuantity,
        specific_consumption: sumReferenceValue,
        reference_value: sumSpecificConsumption,
        deviation: sumDeviation
      });
  }

  useEffect(() => {
    serverRequest();
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <Typography className={styles.title}>
        Direct Emissions from mobile combustion sources
      </Typography>

      <Typography className={styles.pageHeading}>
        {detals?.location}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: theme.palette.esgblueblack.main }}>
              <TableCell className={styles.tableHead}>
                water consumption
              </TableCell>
              <TableCell className={styles.tableHead}>
                quantity in 2022 [m3]
              </TableCell>
              <TableCell className={styles.tableHead}>
                specific consumption [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>
                reference value from 2021 [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>deviation [%]</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detals?.details?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.quantity ? item.quantity : "NA"}</TableCell>
                <TableCell>{item.specific_consumption ? item.specific_consumption : "NA"}</TableCell>
                <TableCell>{item.reference_value ? item.reference_value : "NA"}</TableCell>
                <TableCell>{item.deviation ? item.deviation : "NA"}</TableCell>
              </TableRow>
            ))}
            <TableRow className={styles.totalRow}>
              <TableCell>Total</TableCell>
              <TableCell>{total?.quantity}</TableCell>
              <TableCell>{total?.specific_consumption}</TableCell>
              <TableCell>{total?.reference_value}</TableCell>
              <TableCell>{total?.deviation}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <FooterCard />
    </>
  );
};

export default ViewWaterConsumption;
