/** @format */

import React from "react";
import DataVault from "../DataVault";
import { Stack, Typography } from "@mui/material";
import styles from "./sdgs.module.css";
import SdgCard from "../../../components/UI/SdgCard";

const Sdgs = () => {
  const sdgList = [
    {
      content: "End poverty in all its forms everywhere",
    },
    {
      content:
        "End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
    },
    {
      content:
        "Ensure healthy lives and promote well-being for all at all ages",
    },
    {
      content:
        "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
    },
    {
      content: "Achieve gender equality and empower all women and girls",
    },
    {
      content:
        "Ensure availability and sustainable management of water and sanitation for all",
    },
    {
      content:
        "Ensure access to affordable, reliable, sustainable and modern energy for all",
    },
    {
      content:
        "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all",
    },
    {
      content:
        "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
    },
    {
      content: "Reduce inequality within and among countries",
    },
    {
      content:
        "Make cities and human settlements inclusive, safe, resilient and sustainable",
    },
    {
      content: "Ensure sustainable consumption and production patterns",
    },
    {
      content: "Take urgent action to combat climate change and its impacts*",
    },
    {
      content:
        "Conserve and sustainably use the oceans, seas and marine resources for sustainable development",
    },
    {
      content:
        "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt ......",
    },
    {
      content:
        "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, ......",
    },
    {
      content:
        "Strengthen the means of implementation and revitalize the global partnership for sustainable development",
    },
  ];
  return (
    <DataVault>
      <Stack gap="16px" className={styles.leadershipContainer}>
        <Typography variant="h5" className={styles.pageHeading}>
          Sustainable Development Goals
        </Typography>
        <Typography variant="p" className={styles.explaination}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages,
        </Typography>
        <div className={styles.cardContainer}>
          {sdgList.map((item, index) => (
            <SdgCard item={item.content} index={index} />
          ))}
        </div>
      </Stack>
    </DataVault>
  );
};

export default Sdgs;
