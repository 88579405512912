/** @format */

import { Box } from "@mui/material";
import React from "react";
import styles from "../dataVault/dataVault.module.css";
import OurPortfolio from "../../components/assetManagement/OurPortfolio";
import AssetManagementPlan from "../../components/assetManagement/AssetManagementPlan";
import SustainabilityReporting from "../../components/assetManagement/SustainabilityReporting";
import EsgTargets from "../../components/assetManagement/EsgTargets";
import Tenants from "../../components/assetManagement/Tenants";
import AssetNavBar from "../../components/UI/AssetNavBar";
import { useSelector } from "react-redux";

const AssetManagement = () => {
  const { activeTab } = useSelector((state) => state.asset);

  return (
    <Box className={styles.dataVaultContainer}>
      {/* <Navbar firstLink="Governance" secondLink="ESG Reporting" /> */}
      <AssetNavBar />
      <Box className={styles.componentContainer}>
        {activeTab === 0 && <OurPortfolio />}
        {activeTab === 4 && <AssetManagementPlan />}
        {activeTab === 1 && <SustainabilityReporting />}
        {activeTab === 2 && <EsgTargets />}
        {activeTab === 3 && <Tenants />}
      </Box>
    </Box>
  );
};

export default AssetManagement;
