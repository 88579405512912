/** @format */

export const UserData = [
  { id: 2, year: 2007, userGain: 45677, userLost: 345 },
  { id: 3, year: 2008, userGain: 78888, userLost: 555 },
  { id: 4, year: 2009, userGain: 65662, userLost: 200 },
  { id: 5, year: 2010, userGain: 35326, userLost: 450 },
  { id: 6, year: 2011, userGain: 80000, userLost: 823 },
  { id: 7, year: 2012, userGain: 45677, userLost: 600 },
  { id: 8, year: 2013, userGain: 78888, userLost: 700 },
  { id: 9, year: 2014, userGain: 65662, userLost: 800 },
  { id: 10, year: 2015, userGain: 35326, userLost: 950 },
  { id: 11, year: 2016, userGain: 80000, userLost: 230 },
  { id: 12, year: 2017, userGain: 45677, userLost: 300 },
  { id: 13, year: 2018, userGain: 78888, userLost: 100 },
  { id: 14, year: 2019, userGain: 65662, userLost: 250 },
  { id: 15, year: 2020, userGain: 35326, userLost: 480 },
  { id: 16, year: 2021, userGain: 78888, userLost: 750 },
  { id: 17, year: 2022, userGain: 65662, userLost: 150 },
  { id: 18, year: 2023, userGain: 35326, userLost: 200 },
];

export const PieData = [
  { id: 15, year: 2020, userGain: 35326, userLost: 480 },
  { id: 16, year: 2021, userGain: 78888, userLost: 750 },
  { id: 17, year: 2022, userGain: 65662, userLost: 150 },
  { id: 18, year: 2023, userGain: 35326, userLost: 200 },
];

export const stackedBarData = [
  { id: 1, option1target: 1911, option2target: 1230, day: "M" },
  { id: 2, option1target: 3111, option2target: 2153, day: "T" },
  { id: 3, option1target: 1995, option2target: 950, day: "W" },
  { id: 4, option1target: 1970, option2target: 1208, day: "T" },
  { id: 5, option1target: 2785, option2target: 800, day: "F" },
  { id: 6, option1target: 1615, option2target: 1160, day: "S" },
  { id: 7, option1target: 3756, option2target: 2356, day: "S" },
];
