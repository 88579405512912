/** @format */

import { ArrowBack, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./calculateScope2Details.module.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CalculateScope2Details = () => {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        direction="row"
        gap="16px"
        alignItems="center"
        className={styles.pageTitle}
      >
        <Box className={styles.backArrow}>
          <ArrowBack onClick={() => navigate("/scope2/add-scope2-details")} />
        </Box>
        <Typography className={styles.title}>Back</Typography>
      </Stack>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="confirmation"
        >
          <Typography className={styles.instruction}>
            Fill the details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" gap="32px" sx={{ padding: "16px" }}>
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="demo-category-label">
                Emissions Source{" "}
              </InputLabel>
              <Select
                labelId="demo-category-label"
                name="category"
                // value={targetDetails?.category}
                // onChange={(e) => handelValueChange(e)}
                input={<OutlinedInput label="Emissions Source " />}
                MenuProps={MenuProps}
                sx={{ minWidth: "200px" }}
              >
                {/* {categories.map((category) => (
              <MenuItem key={category?.id} value={category?.name}>
                {category?.name}
              </MenuItem>
            ))} */}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="demo-category-label">Select Asset</InputLabel>
              <Select
                labelId="demo-category-label"
                name="category"
                // value={targetDetails?.category}
                // onChange={(e) => handelValueChange(e)}
                input={<OutlinedInput label="Select Asset" />}
                MenuProps={MenuProps}
                sx={{ minWidth: "200px" }}
              >
                {/* {categories.map((category) => (
              <MenuItem key={category?.id} value={category?.name}>
                {category?.name}
              </MenuItem>
            ))} */}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" gap="32px" sx={{ padding: "16px" }}>
            {" "}
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="demo-category-label">Select Unit</InputLabel>
              <Select
                labelId="demo-category-label"
                name="category"
                // value={targetDetails?.category}
                // onChange={(e) => handelValueChange(e)}
                input={<OutlinedInput label="Select Unit" />}
                MenuProps={MenuProps}
                sx={{ minWidth: "200px" }}
              >
                {/* {categories.map((category) => (
              <MenuItem key={category?.id} value={category?.name}>
                {category?.name}
              </MenuItem>
            ))} */}
              </Select>
            </FormControl>{" "}
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="demo-category-label">
                Total Energy Consumption
              </InputLabel>
              <Select
                labelId="demo-category-label"
                name="category"
                // value={targetDetails?.category}
                // onChange={(e) => handelValueChange(e)}
                input={<OutlinedInput label="Total Energy Consumption" />}
                MenuProps={MenuProps}
                sx={{ minWidth: "200px" }}
              >
                {/* {categories.map((category) => (
              <MenuItem key={category?.id} value={category?.name}>
                {category?.name}
              </MenuItem>
            ))} */}
              </Select>
            </FormControl>{" "}
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="demo-category-label">Green Power %</InputLabel>
              <Select
                labelId="demo-category-label"
                name="category"
                // value={targetDetails?.category}
                // onChange={(e) => handelValueChange(e)}
                input={<OutlinedInput label="Green Power %" />}
                MenuProps={MenuProps}
                sx={{ minWidth: "200px" }}
              >
                {/* {categories.map((category) => (
              <MenuItem key={category?.id} value={category?.name}>
                {category?.name}
              </MenuItem>
            ))} */}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="16px"
            marginTop="24px"
          >
            <Button
              variant="outlined"
              onClick={() => navigate("/scope2/add-scope2-details")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className={styles.btnNext}
              onClick={() => navigate("/scope2/add-scope2-details")}
            >
              Next
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default CalculateScope2Details;
