/** @format */

import React, { useState } from "react";
import img from "../../assets/requestReport.png";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./requestReport.module.css";
import GenerateCustomReportModal from "../../modals/GenerateCustomReportModal";

const RequestReport = () => {
  const [generateReport, setGenerateReport] = useState(false);
  return (
    <>
      <GenerateCustomReportModal
        open={generateReport}
        setOpen={setGenerateReport}
      />
      <Stack
        direction="row"
        gap="70px"
        sx={{
          marginTop: "24px",
        }}
        className={styles.requestReport}
      >
        <Box>
          <Typography variant="h5" className={styles.pageHeading}>
            Want to generate report
          </Typography>
          <Typography
            variant="span"
            className={styles.textContent}
            sx={{ marginTop: "12px" }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
          </Typography>
          <Typography variant="h6" className={styles.listHeading}>
            Including:
          </Typography>
          <ul className={styles.listContainer}>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Carbon footprint
            </li>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Emissions reduction targets
            </li>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Carbon pricing
            </li>
            <li sx={{ padding: "4px 0" }} className={styles.listItem}>
              Renewable energy usuage
            </li>
          </ul>
          <Typography
            variant="a"
            className={styles.link}
            onClick={() => setGenerateReport(true)}
          >
            Click here to submit your request to generate the report
          </Typography>
        </Box>
        <img src={img} alt="img" width="676px" />
      </Stack>
    </>
  );
};

export default RequestReport;
