/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardTargetItem from "../CardTargetItem";

import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";

import { MoreVert } from "@mui/icons-material";
import "./targetList.css";
import {
  getTargets,
  removeTargets,
} from "../../services/private/targets/TargetService";
import ActionModal from "../../modals/ActionModal";
import DeleteModal from "../../modals/DeleteModal";
import { successToast, errorToast } from "../../utils/alerts";
const TargetList = () => {
  
  const [viewActionPlan, setViewActionPlan] = useState(false);


  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const [targets, setTargets] = useState([]);
  const [currentAction, setCurrentAction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [entity, setEntity] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();

  const serverRequest = async () => {
    try {
      setLoading(true);
      const apiResponse = await getTargets();
      setTargets(apiResponse.data);
      setLoading(false);
    } catch (error) {
      errorToast(error.message);
      setLoading(false);
    }
  };

  const handelActionModal = async (actions) => {
    setCurrentAction(actions);
    setViewActionPlan(true);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handelDialogOpen = async (item) => {
    setEntity(item);
    setDialog(true);
    handleClose();
  };

  const handelDialogClose = () => {
    setDialog(false);
  };

  // const handelUpdateTarget = (idVal) => {
  //   navigate(`/targets/update-target/${idVal}`);
  // };

  const handelDelete = async (id) => {
    try {
      setDialog(false);
      await removeTargets(id);
      successToast("Delete Successfully");
      await serverRequest();
    } catch (error) {
      successToast(error.message);
    }
  };

  useEffect(() => {
    serverRequest();
  }, []);

  return (
    <Box id="target-list-tab">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="p" className="page-headline">
          Targets
        </Typography>
        <Button
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={() => navigate("add-new-target")}
        >
          Add New Target
        </Button>
      </Stack>
      {targets.map((itemVal) => {
        return (
          <Stack
            direction="row"
            gap="96px"
            alignItems="center"
            padding="24px"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#F6F7FB",
              borderRadius: "8px",
            }}
            margin="16px 0"
            key={itemVal.id}
          >
            <Box sx={{ maxWidth: "1130px" }}>
              <CardTargetItem targetDetails={itemVal} />
            </Box>
            <Stack direction="row" gap="96px" alignItems="center">
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#4F5367",
                  color: "#4F5367",
                  minWidth: "165px",
                }}
                onClick={() => handelActionModal(itemVal?.actions)}
              >
                VIEW ACTION PLAN
              </Button>
              <IconButton onClick={(e) => handleClick(e, itemVal.id)}>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={1}
              >
                <MenuItem
                  value={itemVal.id}
                  onClick={() =>
                    navigate(`/targets/update-target/${selectedItem}`)
                  }
                >
                  Update
                </MenuItem>
                <MenuItem
                  value={itemVal.id}
                  onClick={() => handelDialogOpen(selectedItem)}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Stack>
          </Stack>
        );
      })}
      <ActionModal
        open={viewActionPlan}
        setOpen={setViewActionPlan}
        actions={currentAction}
      />
      {dialog && (
        <DeleteModal
          open={dialog}
          handelClose={handelDialogClose}
          label={"Are you sure you want to delete this target ?"}
          description={
            "By pressing this delete button will remove this target permanently."
          }
          deleteRow={handelDelete}
          id={entity}
        />
      )}
    </Box>
  );
};

export default TargetList;
