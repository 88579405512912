/** @format */

import {
  ArrowBack,
  Delete,
  ModeEditOutline,
  Visibility,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../pages/dataVault/dataVault.module.css";
import classes from "./assetManagement.module.css";
import AssetNavBar from "../UI/AssetNavBar";

const ViewTenants = () => {
  const navigate = useNavigate();
  const { assetId } = useParams();

  const handleGoBack = () => {
    const newPath = `/asset-management/view-assets/${assetId}`;
    navigate(newPath);
  };
  return (
    <Stack className={styles.dataVaultContainer}>
      <AssetNavBar />
      <Stack
        p={3}
        gap={3}
        className={classes.tenantContainer}
        sx={{ borderRadius: "8px", background: " #FFF" }}
      >
        <Stack direction="row" gap={2}>
          <IconButton
            size="small"
            className={styles.backArrow}
            onClick={handleGoBack}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6">Robert Fox</Typography>
        </Stack>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableHeadRow}>
                <TableCell className={classes.tableHeadCell}>
                  Tenant ID
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Tenant name
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Floor</TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Suite No.
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Area Leased
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Commencement Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Expiry Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Rent</TableCell>
                <TableCell className={classes.tableHeadCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              <TableRow className={classes.tableBodyRow}>
                <TableCell className={classes.tableBodyCell}>20796</TableCell>
                <TableCell className={classes.tableBodyCell}>
                  Robert Fox
                </TableCell>
                <TableCell className={classes.tableBodyCell}>1</TableCell>
                <TableCell className={classes.tableBodyCell}>536</TableCell>
                <TableCell className={classes.tableBodyCell}>
                  5560 sqFt
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  8 Sep, 2020
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  1 Feb, 2020
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  $3,443.00
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  <IconButton size="small">
                    <ModeEditOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="p" className={classes.tableTitle}>
            Legal Documents
          </Typography>
          <Button variant="contained" sx={{ color: "#fff" }}>
            attach document
          </Button>
        </Stack>
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ borderRadius: "4px", background: " #0F333D" }}
            p={2}
          >
            <Typography variant="p" className={classes.docTitle}>
              Document Name
            </Typography>
            <Typography variant="p" className={classes.docTitle}>
              Actions
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ borderRadius: "4px", background: " #F6F7FB" }}
            p={2}
          >
            <Typography variant="p" className={classes.docTable}>
              Legal - 01
            </Typography>
            <Typography variant="p" className={classes.docTitle}>
              <Stack direction="row" gap={2}>
                <IconButton size="small">
                  <Delete sx={{ color: "#82959C" }} />
                </IconButton>
                <IconButton size="small">
                  <Visibility sx={{ color: "#82959C" }} />
                </IconButton>
              </Stack>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ borderRadius: "4px", background: " #F6F7FB" }}
            p={2}
          >
            <Typography variant="p" className={classes.docTable}>
              Legal - 02
            </Typography>
            <Typography variant="p" className={classes.docTitle}>
              <Stack direction="row" gap={2}>
                <IconButton size="small">
                  <Delete sx={{ color: "#82959C" }} />
                </IconButton>
                <IconButton size="small">
                  <Visibility sx={{ color: "#82959C" }} />
                </IconButton>
              </Stack>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ borderRadius: "4px", background: " #F6F7FB" }}
            p={2}
          >
            <Typography variant="p" className={classes.docTable}>
              Legal - 03
            </Typography>
            <Typography variant="p" className={classes.docTitle}>
              <Stack direction="row" gap={2}>
                <IconButton size="small">
                  <Delete sx={{ color: "#82959C" }} />
                </IconButton>
                <IconButton size="small">
                  <Visibility sx={{ color: "#82959C" }} />
                </IconButton>
              </Stack>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ViewTenants;
