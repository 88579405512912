/** @format */

import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import styles from "./dataVaultQuestionnaire.module.css";
import { Upload } from "@mui/icons-material";
import {
  getDataVault,
  postDataVault,
} from "../../../services/private/dataVault/dataVaultService";
import { errorToast, successToast } from "../../../utils/alerts";

const DataVaultQuestionnaire = ({ tab, type }) => {
  // eslint-disable-next-line
  const [file, setFile] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  // eslint-disable-next-line
  const [textAreaInput, setTextAreaInput] = useState("");
  const [disabletextArea, setDisableTextArea] = useState(false);
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  // TO RENDER LIST OF QUSESTIONS
  const serverRequest = async () => {
    try {
      setIsLoading(true);
      const rep = await getDataVault(tab, type);
      setData(rep);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    } catch (error) {
      errorToast(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    serverRequest();
    // eslint-disable-next-line
  }, []);
  const maxWordsLimit = 250;
  const handleFileChange = (event) => {
    setFile(event.target.files[0].name);

    successToast("File uploaded successfully !");
  };
  const handleCheckboxChange = (ques) => {
    const updatedData = data.data?.map((item) => {
      const updatedQuestions = item.questions?.map((question) => {
        if (question.id === ques.id) {
          return {
            ...question,
            answer: question.answer === null ? true : null,
          };
        }
        return question;
      });
      return { ...item, questions: updatedQuestions };
    });

    setData({ data: updatedData });
  };

  const handleData = async () => {
    const payloadData = data.data?.map((item) => {
      const payload = item.questions?.map((question) => {
        return {
          question_id: question.id,
          answer:
            question.input_type === "checkbox"
              ? question.name
              : question.input_type === "checkbox_input"
              ? `other^${question.answer}`
              : (question.input_type === "input" ||
                  question.input_type === "textarea") &&
                question.answer,
        };
      });
      console.log("000", payload);
      return payload;
    });
    const resp = await postDataVault(payloadData.flat());
    successToast(resp.message);
  };
  const handleTextField = (e, param) => {
    console.log(e, param.answer);
    const wordscount = e.trim().split(" ").length;
    if (wordscount > maxWordsLimit) {
      setDisableTextArea(true);
    } else {
      setTextAreaInput(e);
      setDisableTextArea(false);
    }
  };
  const resetHandler = () => {
    serverRequest();
  };
  return (
    <Box
      p={3}
      sx={{ borderRadius: "8px", background: "#F6F7FB" }}
      className={styles.leadershipQuestionnaireContainer}
    >
      {tab === 5 && (
        <Stack gap={1} mb={2}>
          <Typography variant="p" className={styles.tab5}>
            An Environmental Management System (EMS) is an internal framework
            that structures all procedures, projects and tactics into a cohesive
            program which aligns the sustainability efforts at entity level. An
            EMS can assist entities in managing and improving their
            environmental performance, complying with environmental laws and
            regulations, identifying financial savings through more efficient
            operating practices, and improving the standing of the business with
            staff, client companies, partner organizations and other
            stakeholders.
          </Typography>
          <Typography variant="p" className={styles.tab5}>
            Use of an aligned or certified EMS framework provides assurance to
            both the business and external stakeholders that environmental
            impacts are measured and acted upon using a recognized and proven
            methodology. Periodic reviews of the EMS ensure its continuing
            suitability and effectiveness for the entity.
          </Typography>
        </Stack>
      )}
      {!isLoading &&
        data.data?.map((item) => {
          return (
            <Box
              className={item.input_type === "input" ? styles.testing : ""}
              key={item.id}
            >
              {item.input_type === "title" && (
                <>
                  <Typography variant="p" className={styles.questionLabel}>
                    {item.name}
                  </Typography>

                  {item.questions?.map((question) => (
                    <Stack
                      direction="row"
                      // gap="12px"
                      mt={1.5}
                      mb={1.5}
                      key={question.id}
                      alignItems="center"
                    >
                      {question.input_type === "checkbox" ? (
                        <Stack>
                          <Box
                            sx={{ display: "flex", alignItems: "flex-start" }}
                          >
                            <Checkbox
                              checked={question.answer || false}
                              onChange={() => handleCheckboxChange(question)}
                              name={`checkbox-${question.id}`}
                            />
                            {question.name.includes("#") ? (
                              <Typography
                                variant="p"
                                className={styles.questionText}
                                pt={1}
                              >
                                {question.name.split("#")[0]}
                              </Typography>
                            ) : (
                              <Typography
                                variant="p"
                                className={styles.questionText}
                                pt={1}
                              >
                                {question.name}
                              </Typography>
                            )}
                          </Box>
                          {/* to render the list */}
                          {question.name.includes("#") &&
                            question.name
                              .split("#")
                              .splice(1)
                              .map((item2) => (
                                <ul className={styles.list}>
                                  <li className={styles.questionText}>
                                    {item2}
                                  </li>
                                </ul>
                              ))}
                        </Stack>
                      ) : question.input_type === "checkbox_input" ? (
                        <>
                          <Checkbox
                            // checked={question.answer || false}
                            onChange={() => setIsEnabled((prev) => !prev)}
                            name={`checkbox-${question.id}`}
                          />
                          <Typography
                            variant="p"
                            className={styles.questionText}
                          >
                            {question.name}
                          </Typography>
                          <Box sx={{ marginLeft: "10px" }}>
                            <TextField
                              variant="standard"
                              onChange={(e) =>
                                handleTextField(e.target.value, question)
                              }
                              name={question.id}
                              disabled={isEnabled}
                              value={question.answer}
                            />
                          </Box>
                        </>
                      ) : question.input_type === "input" ? (
                        <Box mt={2} mb={2}>
                          <Typography
                            variant="p"
                            className={styles.questionText}
                            sx={{ marginRight: "10px" }}
                          >
                            {question.name}
                          </Typography>
                          <TextField
                            variant="standard"
                            onChange={(e) =>
                              handleTextField(e.target.value, question)
                            }
                            name={question.id}
                            value={question.answer}
                          />
                        </Box>
                      ) : question.input_type === "textarea" ? (
                        <Stack>
                          <Typography
                            variant="p"
                            className={styles.questionText}
                          >
                            {question.name}
                          </Typography>
                          <Box mt={2} mb={2} width={1000}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              multiline={true}
                              onChange={(e) =>
                                handleTextField(e.target.value, question)
                              }
                              name={question.id}
                              value={question.answer}
                            />
                          </Box>
                          {disabletextArea && (
                            <Typography variant="p" sx={{ color: "orangered" }}>
                              {`The maximum word limit is ${maxWordsLimit}!`}
                            </Typography>
                          )}
                        </Stack>
                      ) : (
                        question.input_type === "doc" && (
                          <>
                            <Typography
                              variant="p"
                              className={styles.questionLabel}
                            >
                              {question.name}
                            </Typography>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />

                            <Button
                              startIcon={<Upload color="primary" />}
                              sx={{ marginLeft: "8px" }}
                              onClick={handleFileSelect}
                            >
                              <Typography>Upload File</Typography>
                            </Button>
                          </>
                        )
                      )}
                    </Stack>
                  ))}
                </>
              )}
              {item.input_type === "input" && (
                <>
                  <Typography variant="p" className={styles.questionText}>
                    {item.name}
                  </Typography>
                  <Box mt={2} mb={2} sx={{ marginLeft: "8px" }}>
                    <TextField
                      variant="standard"
                      onChange={(e) => handleTextField(e.target.value, item)}
                      name={item.id}
                      value={item.answer}
                    />
                  </Box>
                </>
              )}
              {item.input_type === "doc" && item.question?.length !== 0 && (
                <>
                  <Typography variant="p" className={styles.questionLabel}>
                    {item.name}
                  </Typography>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <Button
                    startIcon={<Upload color="primary" />}
                    sx={{ marginLeft: "8px" }}
                    onClick={handleFileSelect}
                  >
                    <Typography>Upload File</Typography>
                  </Button>
                </>
              )}
            </Box>
          );
        })}
      {isLoading && <p>Loading...</p>}
      <Stack direction="row" gap="16px" mt={3}>
        <Button variant="contained" sx={{ color: "#fff" }} onClick={handleData}>
          Save
        </Button>
        <Button variant="outlined" onClick={resetHandler}>
          Clear
        </Button>
      </Stack>
    </Box>
  );
};

export default DataVaultQuestionnaire;
