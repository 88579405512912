/** @format */

import { Box, Stack, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataVault from "../DataVault";
import styles from "./leadershipCommitments.module.css";
import LeadershipQuestionnaire from "./DataVaultQuestionnaire";
import AccordionQuestionnaire from "./AccordionQuestionnaire";

const DecisionMaking = () => {
  const [value, setValue] = useState("1");
  // eslint-disable-next-line
  const [answered, setAnswered] = useState(0);
  const handleInnerTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DataVault>
      <Stack gap="16px" className={styles.leadershipContainer}>
        <Typography variant="h5" className={styles.pageHeading}>
          ESG Decision Making
        </Typography>
        <Typography variant="p" className={styles.explaination}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages,
        </Typography>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Typography className={styles.questionCount} pb={1}>
            Questions Answered: {answered}/23
          </Typography>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleInnerTab}>
                <Tab label="1.	ESG Decision Making" value="1" />

                <Tab label="2. Commitment to ESG" value="2" />
                <Tab label="3. ESG Incident Monitoring" value="3" />
                <Tab
                  label="4. ESG Due diligence for new acquisitions, developments and/or projects"
                  value="4"
                  sx={{ maxWidth: "630px" }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <AccordionQuestionnaire tab={1} type={1} />
            </TabPanel>
            <TabPanel value="2">
              <LeadershipQuestionnaire tab={2} type={1} />
            </TabPanel>
            <TabPanel value="3">
              <LeadershipQuestionnaire tab={3} type={1} />
            </TabPanel>
            <TabPanel value="4">
              <LeadershipQuestionnaire tab={4} type={1} />
            </TabPanel>
          </TabContext>
        </Box>
      </Stack>
    </DataVault>
  );
};

export default DecisionMaking;
