/** @format */

import React from "react";
import TargetList from "../../components/targets/TargetList";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import TabComponent from "../../components/UI/TabComponent";
import TargetsAnalytics from "../../components/targets/TargetsAnalytics";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="p">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Targets = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <TabComponent
        tab1Name={"LIST"}
        tab1Width={"230px"}
        tab2Name={"ANALYTICS"}
        tab2Width={"348px"}
        value={value}
        handleChange={handleChange}
      />

      <CustomTabPanel value={value} index={0} className="target-list-tab-width">
        <TargetList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TargetsAnalytics />
      </CustomTabPanel>
    </Box>
  );
};

export default Targets;
