/** @format */

import React,{ useState, useEffect } from "react";
import styles from "./viewWasteConsumption.module.css";

import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../components/theme";
import { requestWasteConsumptionDetails } from '../../services/private/waste/WasteService';
import { useParams } from 'react-router-dom';
import {errorToast } from '../../utils/alerts';

const ViewWasteConsumption = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [detals, setDetails] = useState([]);
    const [total, setTotal] = useState({
      quantity: 0,
      specific_consumption: 0,
      reference_value: 0,
      deviation: 0 
    });
    
  const serverRequest = async () => {
    try{
      const response = await requestWasteConsumptionDetails(id);
      setDetails(response.data);
      calculateTotal(response.data.details);
    }catch(error){
      errorToast(error.message)
    }
  }

  const calculateTotal = async(items) => {

    const sum = (dataArray, property) => {
      return dataArray.reduce((total, item) => total+ parseInt(item[property] || 0), 0);
    }
    const totalQuantityHazard = sum(items.hazard, "quantity");
    const totalDeviationHazard = sum(items.hazard, "deviation");
    const totalSpecificConsumptionHazard = sum(items.hazard, "specific_consumption");
    const totalReferenceValueHazard = sum(items.hazard, "reference_value");
    
    const totalQuantityNonHazard = sum(items.nonHazard, "quantity");
    const totalDeviationNonHazard = sum(items.nonHazard, "deviation");
    const totalSpecificConsumptionNonHazard = sum(items.nonHazard, "specific_consumption");
    const totalReferenceValueNonHazard = sum(items.nonHazard, "reference_value");

    setTotal({
      quantity: parseFloat(totalQuantityHazard) + parseFloat(totalQuantityNonHazard),
      specific_consumption: parseFloat(totalSpecificConsumptionHazard) + parseFloat(totalSpecificConsumptionNonHazard),
      reference_value: parseFloat(totalReferenceValueHazard) + parseFloat(totalReferenceValueNonHazard),
      deviation: parseFloat(totalDeviationHazard) + parseFloat(totalDeviationNonHazard)
    })    
  }

  useEffect(() => {
    serverRequest();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Stack direction="row" gap="16px" alignItems="center" margin="24px">
        <Box className={styles.backArrow}>
          <ArrowBack onClick={() => navigate("/waste")} />
        </Box>
        <Stack direction="row" gap="16px" alignItems="center">
          <Typography className={styles.title}>Waste Consumption</Typography>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99935 1.66663C5.39935 1.66663 1.66602 5.39996 1.66602 9.99996C1.66602 14.6 5.39935 18.3333 9.99935 18.3333C14.5993 18.3333 18.3327 14.6 18.3327 9.99996C18.3327 5.39996 14.5993 1.66663 9.99935 1.66663ZM10.8327 14.1666H9.16602V12.5H10.8327V14.1666ZM10.8327 10.8333H9.16602V5.83329H10.8327V10.8333Z"
              fill="#0F333D"
            />
          </svg>
        </Stack>
      </Stack>
      <Typography className={styles.pageHeading}>{detals?.location}</Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ bgcolor: theme.palette.esgblueblack.main }}>
              <TableCell className={styles.tableHead}>waste in 2022</TableCell>
              <TableCell className={styles.tableHead}>
                type of recycling
              </TableCell>
              <TableCell className={styles.tableHead}>quantity [m3]</TableCell>
              <TableCell className={styles.tableHead}>
                specific consumption [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>
                reference value from 2021 [m3/m2]
              </TableCell>
              <TableCell className={styles.tableHead}>deviation [%] </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell className={styles.tableCell} rowSpan={4}>
                Not hazardous
              </TableCell>
            </TableRow>
            {detals?.details?.nonHazard.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={styles.tableCell}>{item.sub_title}</TableCell>
                <TableCell className={styles.tableCell}>
                  {item.quantity || 'NA'}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.specific_consumption || 'NA'}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.reference_value || 'NA'}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.deviation || 'NA'}
                </TableCell>
              </TableRow>
            ))}
             <TableRow >
              <TableCell className={styles.tableCell} rowSpan={3}>
                Hazardous
              </TableCell>
            </TableRow>
            {detals?.details?.hazard.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={styles.tableCell}>{item.sub_title}</TableCell>
                <TableCell className={styles.tableCell}>
                  {item.quantity || 'NA'}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.specific_consumption || 'NA'}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.reference_value || 'NA'}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {item.deviation || 'NA' }
                </TableCell>
              </TableRow>
            ))}
            <TableRow >
              <TableCell rowSpan={3} className={styles.tableFooterRow}>
                TotalWaste
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableFooterRow}>
              <TableCell></TableCell>
              <TableCell>{total?.quantity || 0}</TableCell>
              <TableCell>{total?.specific_consumption || 0}</TableCell>
              <TableCell>{total?.reference_value || 0}</TableCell>
              <TableCell>{total?.deviation || 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ViewWasteConsumption;
