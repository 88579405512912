/** @format */

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import styles from "./charts.module.css";
import "chartjs-plugin-datalabels";

const DoughnutChart = ({
  chartData,
  type,
  chartHeight,
  containerMaxHeight = 388.5,
  bgColor = "#f6f7fb",
  borderRadius = 6,
}) => {
  const options = {
    // cutout: "50%",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: "auto",
        color: "#fff",
        font: {
          weight: "bold",
        },
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex] + ": " + value;
        },
      },
    },
  };
  return (
    <Box
      className={styles.doughnutContainer}
      sx={{
        maxHeight: containerMaxHeight,
        bgcolor: bgColor,
        borderRadius: borderRadius + "px",
      }}
    >
      <Box className={styles.titleContainer}>
        <Typography className={styles.chartTitle} sx={{ height: "67px" }}>
          {type}
        </Typography>
      </Box>
      <Stack direction="row" justifyItems="space-around">
        <Box
          className={styles.doughnutChart}
          sx={{ height: chartHeight + "px" }}
        >
          <Doughnut data={chartData} options={options} />
        </Box>
        <ul className={styles.listItems}>
          <li className={styles.listItem}>
            <div className={styles.colorBox1}></div>
            <Typography className={styles.legend}>
              123 George Street, Sydney
            </Typography>
          </li>
          <li className={styles.listItem}>
            <div className={styles.colorBox3}></div>
            <Typography className={styles.legend}>
              123 George Street, Sydney
            </Typography>
          </li>
          <li className={styles.listItem}>
            <div className={styles.colorBox2}></div>
            <Typography className={styles.legend}>
              123 George Street, Sydney
            </Typography>
          </li>
          <li className={styles.listItem}>
            <div className={styles.colorBox4}></div>
            <Typography className={styles.legend}>Others</Typography>
          </li>
        </ul>
      </Stack>
    </Box>
  );
};

export default DoughnutChart;
