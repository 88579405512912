/** @format */

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import moment from "moment";

const CardTargetItem = ({ targetDetails }) => {
  return (
    <Box className="card-target-item" sx={{ width: "1135px" }}>
      <Typography variant="p" className="step-3-headline">
        {`  ${targetDetails?.shift === "0" ? "Decrease" : "Increase"} ${
          targetDetails?.shift_value +
          (targetDetails?.shift_type === "0" ? "%" : "")
        } of emission created ${
          targetDetails?.type === "1" ||
          targetDetails?.type === "2" ||
          targetDetails?.type === "3"
            ? "by " +
              targetDetails?.division +
              "(" +
              targetDetails?.category +
              ")"
            : ""
        } at ${targetDetails?.location + " office"} by ${
          targetDetails?.achieve_by?.$y ||
          moment(targetDetails?.achieve_by).format("YYYY")
        }
      `}
      </Typography>
      <Stack direction="row" gap="8px" alignItems="center" height="28px">
        <Typography variant="p" className="action-sub-heading">
          {targetDetails?.type === "0"
            ? "Energy Consumption"
            : targetDetails?.type === "1"
            ? "Scope 1"
            : targetDetails?.type === "2"
            ? "Scope 2"
            : targetDetails?.type === "3"
            ? "Scope 3"
            : targetDetails?.type === "4"
            ? "Water Consumption"
            : "Waste Consumption"}
        </Typography>
        <span>|</span>
        <Typography variant="p" className="action-sub-heading">
          Assigned to {targetDetails?.assign_to}
        </Typography>
        <span>|</span>
        <Typography variant="p" className="action-sub-heading status">
          Neutral
        </Typography>
      </Stack>
    </Box>
  );
};

export default CardTargetItem;
